export const verbPluralHelper = (length: number) =>
  length === 1 ? "is" : "are";

const pluralHelper = (
  length: number,
  word: string,
  returnCount = true
): string => {
  return `${returnCount ? length : ""} ${word}${length === 1 ? "" : "s"}`;
};

export default pluralHelper;
