import { z } from "zod";

import { FilePatchSchema, FileSchema } from "./file";
import { FolderPatchSchema, FolderSchema } from "./folder";
import { LinkPatchSchema, LinkSchema } from "./link";

export const LibraryItemSchema = z.union([
  FileSchema,
  LinkSchema,
  FolderSchema,
]);

export type LibraryItem = z.infer<typeof LibraryItemSchema>;

export const LibraryItemPatchSchema = z.union([
  FilePatchSchema,
  LinkPatchSchema,
  FolderPatchSchema,
]);

export type LibraryItemPatch = z.infer<typeof LibraryItemPatchSchema>;
