import { z } from "zod";

import { AvailabilityBlockSchema } from "@lib/data/schemas/availability-block";

import { getSchemaDefinition } from "../common";

const tags = ["availability-blocks"];

const AvailabilityBlockBodySchema = AvailabilityBlockSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  accountId: true,
});

export type AvailabilityBlockBodyType = z.infer<
  typeof AvailabilityBlockBodySchema
>;

const AvailabilityPathSchema = z.object({
  userId: z.string(),
});

export const postCreateAvailability = getSchemaDefinition(
  "/api/v1/users/{userId}/availability-blocks",
  "post",
  {
    path: AvailabilityPathSchema,
    body: AvailabilityBlockBodySchema,
  },
  {
    description: "Create an availability block",
    tags,
  },
  AvailabilityBlockSchema
);

export const postUpdateAvailability = getSchemaDefinition(
  "/api/v1/users/{userId}/availability-blocks/{availabilityBlockId}",
  "post",
  {
    path: AvailabilityPathSchema.merge(
      z.object({
        availabilityBlockId: z.string(),
      })
    ),
    body: AvailabilityBlockBodySchema,
  },
  {
    description: "Update an availability block",
    tags,
  },
  AvailabilityBlockSchema
);

export const deleteAvailability = getSchemaDefinition(
  "/api/v1/users/{userId}/availability-blocks/{availabilityBlockId}",
  "delete",
  {
    path: AvailabilityPathSchema.merge(
      z.object({
        availabilityBlockId: z.string(),
      })
    ),
  },
  {
    description: "Delete an availability block",
    tags,
  },
  z.object({
    status: z.literal("ok"),
  })
);

export const getAvailabilities = getSchemaDefinition(
  "/api/v1/users/{userId}/availability-blocks",
  "get",
  {
    path: AvailabilityPathSchema,
  },
  {
    description: "Get availabilities for a user",
    tags,
  },
  z.array(AvailabilityBlockSchema)
);

export const getAvailability = getSchemaDefinition(
  "/api/v1/users/{userId}/availability-blocks/{availabilityBlockId}",
  "get",
  {
    path: AvailabilityPathSchema.merge(
      z.object({
        availabilityBlockId: z.string(),
      })
    ),
  },
  {
    description: "Get an availability block",
    tags,
  },
  AvailabilityBlockSchema
);
