import { z } from "zod";

import { getSchemaDefinition } from "../common";

const PathSchema = z.object({
  userId: z.string(),
});

export const createLabel = getSchemaDefinition(
  "/api/v1/users/{userId}/labels",
  "post",
  {
    path: PathSchema,
    body: z.object({ title: z.string(), color: z.string() }),
  },
  {
    description: "Create a label",
    tags: ["labels"],
  },
  z.object({ id: z.string() })
);
