import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import {
  AccessTypeSchema,
  AccountSchema,
  ExtendedAccountSchema,
} from "@lib/data/schemas/account";

const tags = ["accounts"];

const CommonPathSchema = z.object({
  apiVersion: z.string().default("v1"),
  userId: z.string(),
});

export const getOrganizationAccounts = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts",
  "get",
  {
    path: CommonPathSchema,
  },
  {
    description: "Returns the list of all accounts for the organization",
    tags,
  },
  z.array(ExtendedAccountSchema)
);

export const addOrganizationAccount = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/add-account",
  "post",
  {
    path: CommonPathSchema,
    body: z.object({
      email: z.string(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      accessType: AccessTypeSchema,
      skipEmail: z.boolean().optional().default(false),
    }),
  },
  {
    description: "Adds the account to the organization",
    tags,
  },
  z.object({
    account: AccountSchema,
    inviteLink: z.string(),
  })
);

export const setAccountPassword = getSchemaDefinition(
  "/api/{apiVersion}/accounts/{accountId}/set-password",
  "post",
  {
    path: z.object({ accountId: z.string(), apiVersion: z.string() }),
    body: z.object({ password: z.string(), encryptedData: z.string() }),
  },
  {
    description: "Updates account password",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const accountSignUp = getSchemaDefinition(
  "/api/{apiVersion}/accounts/sign-up",
  "post",
  {
    path: z.object({ apiVersion: z.string() }),
    body: z.object({
      orgId: z.string(),
      accountId: z.string().optional(),
      email: z.string(),
      displayName: z.string(),
      password: z.string(),
    }),
  },
  {
    description: "Creates firebase auth account",
    tags,
  },
  z.object({ token: z.string() })
);

const WorkspaceSchema = z.object({
  organization: z.any(),
  organizationOwner: z.any(),
  clientId: z.string().optional(),
});

export type WorkspaceType = z.infer<typeof WorkspaceSchema>;

export const getAccountContext = getSchemaDefinition(
  "/api/{apiVersion}/accounts/{accountId}/contexts",
  "get",
  {
    path: z.object({ accountId: z.string(), apiVersion: z.string() }),
  },
  {
    description: "Gets all contexts for the account",
    tags,
  },
  z.object({
    member: z.array(WorkspaceSchema),
    client: z.array(WorkspaceSchema),
    workspaces: z.array(WorkspaceSchema),
  })
);

export const updateAccountPermissions = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/{accountId}/permissions",
  "put",
  {
    path: CommonPathSchema.extend({
      accountId: z.string(),
    }),
    body: z.object({ accessType: AccessTypeSchema }),
  },
  {
    description: "Updates the account permissions",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const getOrganizationAccount = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/{accountId}",
  "get",
  {
    path: CommonPathSchema.extend({
      accountId: z.string(),
    }),
  },
  {
    description: "Get an organization account",
    tags,
  },
  ExtendedAccountSchema
);

export const removeOrganizationAccount = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/{accountId}",
  "delete",
  {
    path: CommonPathSchema.extend({
      accountId: z.string(),
    }),
  },
  {
    description: "Removes an account from the organization",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const updateOrganizationAccount = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/{accountId}",
  "put",
  {
    path: CommonPathSchema.extend({
      accountId: z.string(),
    }),
    body: AccountSchema.partial(),
  },
  {
    description: "Update an organization account",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const syncAccountPrimaryGoogleCalendar = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/accounts/{accountId}/sync",
  "put",
  {
    path: CommonPathSchema.extend({
      accountId: z.string(),
    }),
    body: z.object({
      calendarId: z.string(),
    }),
  },
  {
    description: "Syncs the primary Google Calendar of an account",
    tags,
  },
  z.object({ ok: z.boolean() })
);
