import { LibraryItemPatchSchema } from "types/db/library";
import { LibraryResourceTypeSchema } from "types/db/shared";
import { z } from "zod";

import { getSchemaDefinition } from "../common";

const PathSchema = z.object({
  userId: z.string(),
  resourceType: LibraryResourceTypeSchema,
  resourceId: z.string(),
});

export const shareBaseLibraryItemWithClient = getSchemaDefinition(
  "/api/v1/users/{userId}/library/{resourceType}/{resourceId}/share",
  "patch",
  {
    path: PathSchema,
    body: z.object({ clientIds: z.array(z.string()), merge: z.boolean() }),
  },
  {
    description: "Description 1",
    tags: ["library"],
    inputDescriptions: {
      path: {
        resourceId: "Library item id",
      },
    },
  },
  // TODO
  z.object({ id: z.string() })
);

export const startBaseLibraryItemBroacast = getSchemaDefinition(
  "/api/v1/users/{userId}/library/{resourceType}/{resourceId}/broadcast",
  "post",
  {
    path: PathSchema,
  },
  {
    description: "Start library item broadcast",
    tags: ["library"],
  },
  z.object({ id: z.string() })
);

export const stopBaseLibraryItemBroadcast = getSchemaDefinition(
  "/api/v1/users/{userId}/library/{resourceType}/{resourceId}/broadcast",
  "delete",
  {
    path: PathSchema,
  },
  {
    description: "Stop library item broadcast",
    tags: ["library"],
  },
  z.object({ id: z.string() })
);

export const updateLibraryItem = getSchemaDefinition(
  "/api/v1/users/{userId}/library/{resourceType}/{resourceId}",
  "patch",
  {
    path: PathSchema,
    body: LibraryItemPatchSchema,
  },
  {
    description: "Description 2",
    tags: ["library"],
  },
  // TODO
  z.object({ id: z.string() })
);
