import z from "zod";

import { LibraryItemBaseSchema } from "./shared";

export const FileSchema = LibraryItemBaseSchema.extend({
  url: z.string(),
  fileName: z.string(),
  size: z.number(),
  contentType: z.number(),
  fullPath: z.string(),
  bucket: z.string(),
  extension: z.string(),
  formId: z.string().optional(),
  legacy: z.boolean().optional(),
});

export type File = z.infer<typeof FileSchema>;

export const FilePatchSchema = z.object({
  fileName: z.string().optional(),
  status: z.string().optional(),
});
