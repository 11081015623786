import { z } from "zod";

import { getSchemaDefinition } from "../common";

const tags = ["products"];

export const postCreatePayment = getSchemaDefinition(
  "/api/v1/users/{userId}/products/{productId}",
  "post",
  {
    path: z.object({ userId: z.string(), productId: z.string() }),
    body: z.object({
      email: z.string(),
      promoCode: z.string().optional(),
      paymentMethod: z.string(),
    }),
  },
  {
    description:
      "Create a payment for a product. Returns a redirect_url to the Stripe invoice.",
    tags,
  },
  z.object({
    redirect_url: z.string(),
    paymentId: z.string(),
  })
);

export const deleteProduct = getSchemaDefinition(
  "/api/v1/users/{userId}/products/{productId}",
  "delete",
  {
    path: z.object({ userId: z.string(), productId: z.string() }),
  },
  {
    description: "Delete a product.",
    tags,
  },
  z.object({
    status: z.string(),
  })
);
