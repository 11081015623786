import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: String(process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID),
  clientToken: String(process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
  site: "us5.datadoghq.com",
  service: "app.practice.do",
  env: process.env.PRACTICE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.PRACTICE_APP_VERSION,
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

export { datadogRum };
