import { isDate, isString } from "lodash";
import { z } from "zod";

export const ZodDateAsString = z.preprocess((arg) => {
  if (isString(arg) || isDate(arg)) return new Date(arg);
}, z.date());

export const ZodDateAsTimestamp = z.preprocess((arg: { _seconds: number }) => {
  if (arg?._seconds) return new Date(arg._seconds * 1000);
}, z.date());
