import { useCallback, useState } from "react";

export const useCallbackRef = () => {
  const [node, setNode] = useState();

  const ref = useCallback((node) => {
    if (node !== null) setNode(node);
  }, []);

  return [ref, node];
};
