import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Transition } from "@headlessui/react";
import { createPopper, Placement } from "@popperjs/core";
import classNames from "classnames";

export enum TooltipSize {
  larger = "max-w-sm",
  large = "max-w-xs",
  medium = "max-w-xxs",
  small = "max-w-xxxs",
}

export enum TooltipKind {
  regular = "regular",
  button = "button",
  left = "left",
}

export enum TooltipVariant {
  theme = "bg-foreground text-background",
  white = "bg-white text-black-ink",
  black = "bg-black-ink text-white",
}

interface TooltipProps {
  trigger: ReactNode;
  children: ReactNode;
  className?: string;
  size?: TooltipSize;
  kind?: TooltipKind;
  placement?: Placement;
  variant?: TooltipVariant;
  contentClassName?: string;
}

export const Tooltip: FC<TooltipProps> = ({
  trigger,
  children,
  className,
  size = TooltipSize.medium,
  kind = TooltipKind.regular,
  variant = TooltipVariant.theme,
  placement = "top-start",
  contentClassName,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toggleTooltip = useCallback(() => {
    setShow((show) => !show);
  }, []);

  const createTooltip = () => {
    if (!triggerRef.current || !tooltipRef.current) return;
    createPopper(triggerRef.current, tooltipRef.current, {
      placement,
    });
  };

  useEffect(() => {
    if (!show) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        createTooltip();
      }, 300);
      return () => clearTimeout(timer);
    } else {
      if (isVisible) return;
      createTooltip();
      setIsVisible(true);
      return () => {};
    }
  }, [show, placement]);

  const getTooltipInfo = useCallback(() => {
    return {
      content: (
        <div
          className={classNames(
            kind === "button" && "py-1 px-3 mb-1 ml-0 mt-2 mr-0 text-xs",
            kind === "left" && "mb-36 -mr-6",
            "z-50 p-4 mb-2 m-4 rounded-2xl font-normal text-sm",
            size,
            variant,
            contentClassName
          )}
        >
          {children}
        </div>
      ),
      transitionProps: {
        enterFrom: "opacity-0 translate-y-2",
        enterTo: "opacity-100 translate-y-0",
        leaveFrom: "opacity-100 translate-y-0",
        leaveTo: "opacity-0 translate-y-2",
      },
    };
  }, [kind, children, size, variant]);

  const { content, transitionProps } = useMemo(
    () => getTooltipInfo(),
    [getTooltipInfo]
  );

  return (
    <div
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
      className={className}
    >
      <div className="info" ref={triggerRef}>
        {trigger}
      </div>
      <div className="tooltip z-50" ref={tooltipRef}>
        <Transition
          show={show}
          enter="transform transition-all ease-out duration-300"
          leave="transform transition-all ease-in duration-200"
          {...transitionProps}
        >
          {content}
        </Transition>
      </div>
    </div>
  );
};
