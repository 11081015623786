import {
  DigitalSignatureSchema,
  DropboxSignDataSchema,
} from "types/digitalsignature";
import { z } from "zod";

import { getSchemaDefinition } from "../common";

const tags = ["dropboxsign"];

const DropboxSignCommonPathSchema = z.object({
  userId: z.string(),
});

const DropboxSignSignaturePostPayloadSchema = z.object({
  signer: z.object({
    name: z.string(),
    emailAddress: z.string(),
  }),
  clientId: z.string(),
  templateId: z.string(),
});

export type DropboxSignSignaturePostPayload = z.infer<
  typeof DropboxSignSignaturePostPayloadSchema
>;

const DropboxSignSignaturePostResponseSchema = z.object({
  signatureRequestId: z.string(),
  signatureId: z.string().optional(),
  signUrl: z.string(),
  expiresAt: z.number().optional(),
});

export const postSignature = getSchemaDefinition(
  "/api/v1/users/{userId}/dropboxsign/signature",
  "post",
  {
    path: DropboxSignCommonPathSchema,
    body: DropboxSignSignaturePostPayloadSchema,
  },
  {
    description:
      "It returns the signUrl to open the Hellosign embedded to the client signs a document",
    tags,
  },
  DropboxSignSignaturePostResponseSchema
);

const DigitalSignatureServiceTypeEmuns = z.enum(["DROPBOX_SIGN"]);

const FileSchema = z.object({
  url: z.string(),
  fileName: z.string(),
});

const DropboxSignTemplatePostPayloadSchema = z.object({
  clientId: z.string(),
  file: FileSchema,
});

const DropboxSignTemplatePostResponseSchema = z.object({
  serviceType: DigitalSignatureServiceTypeEmuns,
  serviceData: DropboxSignDataSchema,
  dateCreated: z.date(),
  dateUpdated: z.date(),
  createdUserId: z.string(),
  updatedUserId: z.string(),
  templateFileId: z.string().optional(),
  signatures: z.array(DigitalSignatureSchema),
});

export const postTemplate = getSchemaDefinition(
  "/api/v1/users/{userId}/dropboxsign/template",
  "post",
  {
    path: DropboxSignCommonPathSchema,
    body: DropboxSignTemplatePostPayloadSchema,
  },
  {
    description:
      "Returns the template id that will be used in the signature flow",
    tags,
  },
  DropboxSignTemplatePostResponseSchema
);

const DropboxSignTemplatePatchPayloadSchema = z.object({
  templateId: z.string(),
});

const DropboxSignTemplatePatchResponseSchema = z.object({
  embedded: z.object({
    editUrl: z.string(),
    expiresAt: z.number(),
  }),
});

export const patchTemplate = getSchemaDefinition(
  "/api/v1/users/{userId}/dropboxsign/template",
  "patch",
  {
    path: DropboxSignCommonPathSchema,
    body: DropboxSignTemplatePatchPayloadSchema,
  },
  {
    description: "Returns the url to edit a template using embedded",
    tags,
  },
  DropboxSignTemplatePatchResponseSchema
);

const DropboxSignApplicationResponseSchema = z.object({
  dropboxSignClientId: z.string().nullable(),
});

export const getApplication = getSchemaDefinition(
  "/api/v1/users/{userId}/dropboxsign/application",
  "get",
  {
    path: DropboxSignCommonPathSchema,
  },
  {
    description:
      "Returns a Dropbox Sign application client Id for a given coach.",
    tags,
  },
  DropboxSignApplicationResponseSchema
);
