import { getOrganizationAccount } from "api-services/definitions/accounts";
import { useApiGet } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { ExtendedAccountType } from "@lib/data/schemas/account";

type UseOrganizationAccountType = (id?: string) => {
  account?: ExtendedAccountType;
  loading: boolean;
};

const useOrganizationAccount: UseOrganizationAccountType = (id) => {
  const { aid, oid, currentWorkspace, loading: loadingAuth } = useAuth();
  const userId = currentWorkspace?.id || id || oid;
  const { data, loading: loadingAccounts } = useApiGet(
    userId && aid ? getOrganizationAccount : undefined,
    userId && aid
      ? {
          userId,
          accountId: aid,
          apiVersion: "v1",
        }
      : undefined,
    {},
    { dedupingInterval: 600000 }
  );

  return {
    account: data,
    loading: loadingAccounts || loadingAuth,
  };
};

export const useHasFullAccess = () => {
  const { account } = useOrganizationAccount();
  return account?.accessType === "full";
};

export default useOrganizationAccount;
