import { z } from "zod";

import { getSchemaDefinition } from "../common";

const QuerySchema = z.object({
  userId: z.string(),
  assigneeId: z.string().optional(),
});

const CandidateClientSchema = z.object({
  name: z.string().optional(),
  email: z.string(),
  upcoming: z.date().optional(),
  past: z.date().optional(),
  eventCount: z.number(),
  id: z.string(),
});

export type CandidateClient = z.infer<typeof CandidateClientSchema>;

export const getClientCandidates = getSchemaDefinition(
  "/api/v1/clients/import",
  "get",
  {
    query: QuerySchema,
  },
  {
    description: "Getting list of candidate clients",
    tags: ["import clients"],
  },
  z.array(CandidateClientSchema)
);

const ImportCandidateSchema = z.object({
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  labels: z.array(z.string()).optional(),
  coachUserId: z.string(),
});

export type ImportCandidate = z.infer<typeof ImportCandidateSchema>;

export const importCandidateClients = getSchemaDefinition(
  "/api/v1/clients/import",
  "post",
  {
    query: QuerySchema,
    body: z.object({
      clients: z.array(ImportCandidateSchema),
    }),
  },
  {
    description: "imorting list of candidate clients",
    tags: ["import clients"],
  },
  z.object({ ok: z.boolean() })
);
