import axios, { AxiosError } from "axios";

/**
 * This catch error is to be used from the APIs created on @practice/sdk
 * */
export const catchErrorSDK = async (e: any, fallBackMessage: string) => {
  let error;
  try {
    const data = (await e?.response?.body?.getReader().read())?.value;
    if (data) {
      const str = String.fromCharCode(...data);
      error = JSON.parse(str);
    }
  } catch (e) {
    console.log("Unexpected Error", e);
  }

  return error?.message || fallBackMessage;
};

/**
 * This catch error is to be used from the APIs created on @practice/web
 * */
const catchErrorClient = (e: unknown, fallBackMessage: string) => {
  const error = e as AxiosError;
  let errorMessage = fallBackMessage;

  if (axios.isAxiosError(error)) {
    errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.errorMessage ||
      error?.response?.data?.error ||
      fallBackMessage;
  }

  return errorMessage;
};

export const getErrorCode = (e: unknown) => {
  const error = e as AxiosError;

  return error?.response?.data?.error?.code;
};

export default catchErrorClient;
