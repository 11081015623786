import { UpdateData } from "firebase/firestore";
import { useDocument } from "swr-firebase";

import { AccountType } from "@lib/data/schemas/account";

type UseAccountType = (accountId: string | undefined | null) => {
  account: AccountType | undefined;
  loading: boolean;
  update: (data: UpdateData<AccountType>) => Promise<void> | null;
};

const useAccount: UseAccountType = (accountId) => {
  const { data, loading, update } = useDocument<AccountType>(
    accountId ? `accounts/${accountId}` : null,
    { listen: true }
  );

  const exists =
    data?.exists &&
    !data.isDeleted &&
    !data.deletedAt &&
    data.status !== "deleted";
  const account = exists ? data : undefined;

  return {
    account,
    loading: accountId ? loading : false,
    update,
  };
};

export default useAccount;
