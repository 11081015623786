const isEmpty = (value) => {
  return value == null || value.length === 0;
};

const displayNameFromContact = (contact, firstNameOnly = false) => {
  if (!contact) {
    return null;
  }

  let displayName = "";
  if (!isEmpty(contact.firstName)) {
    displayName = contact.firstName;
  }

  if (!firstNameOnly) {
    if (!isEmpty(contact.lastName)) {
      if (!isEmpty(displayName)) {
        displayName += " " + contact.lastName;
      } else {
        displayName = contact.lastName;
      }
    }
  }

  if (isEmpty(displayName)) {
    if (!isEmpty(contact.email)) {
      displayName = contact.email;
    } else if (!isEmpty(contact.phoneNumber)) {
      displayName = contact.phoneNumber;
    }
  }

  // use toLowerCase() to make caseSensitive in case of a _sortBy
  return displayName;
};

const COLLECTIONS = ["forms", "tasks", "files", "links", "notes", "invoices"];

export { COLLECTIONS, displayNameFromContact };
