import { z } from "zod";

const CalendarSchema = z
  .object({
    id: z.string(),
    accessRole: z.enum(["owner", "writer", "reader", "freeBusyReader"]),
    primary: z.boolean().optional(),
    summary: z.string(),
  })
  .passthrough(); // We don't know what else we need downstream, just pass everything

export const GcalSchema = z
  .object({
    calendars: CalendarSchema.array(),
  })
  .passthrough(); // We don't know what else we need downstream, just pass everything

export type GcalType = z.infer<typeof GcalSchema>;

export const CalendarIntegrationSchema = z.object({
  gcal: GcalSchema,
  gcalServerToken: z.any(),
});

export type CalendarIntegrationType = z.infer<typeof CalendarIntegrationSchema>;

const GoogleSheetIntegrationSchema = z
  .object({
    organizationId: z.string(),
    sheetId: z.string().nullable(),
    token: z.any(),
  })
  .passthrough(); // We don't know what else we need downstream, just pass everything

export type GoogleSheetIntegrationType = z.infer<
  typeof GoogleSheetIntegrationSchema
>;

export const IntegrationsSchema = z.object({
  calendars: z.array(CalendarIntegrationSchema).optional(),
  emails: z.any().optional(),
  sheets: z.record(z.string(), GoogleSheetIntegrationSchema).optional(),
});

export type IntegrationsType = z.infer<typeof IntegrationsSchema>;
