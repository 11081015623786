import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

const tags = ["emails"];

export const postCoachGeneratedEmail = getSchemaDefinition(
  "/api/{apiVersion}/coachGeneratedEmail",
  "post",
  {
    body: z.object({
      coach_email: z.string(),
      client_email: z.string(),
      email_title: z.string(),
      email_content: z.string(),
      coach_full_name: z.string(),
      coach_first_name: z.string(),
      chat_url: z.string().optional(),
      coach_id: z.string(),
    }),
  },
  {
    description: "Sends an email to the client as a coach",
    tags,
  },
  z.object({
    success: z.boolean(),
  })
);
