import z from "zod";

export const DocumentSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date().optional(),
  __refPath: z.string(),
  __parentId: z.string().optional(),
});

export const LibraryItemBaseSchema = DocumentSchema.extend({
  status: z.string(),
  sharedWith: z.array(z.string()).optional(),
  sharedWithData: z.record(z.date()).optional(),
  legacy: z.boolean().optional(),
  path: z.string().optional(),
  broadcasted: z
    .object({
      date: z.date(),
    })
    .optional(),
});

export const LibraryResourceTypeSchema = z.enum(["files", "links", "folders"]);

export type LibraryResourceType = z.infer<typeof LibraryResourceTypeSchema>;

export const toZodProps = <T>(...props: (keyof T)[]) =>
  props.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {}
  );
