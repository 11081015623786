import z from "zod";

import { LibraryItemBaseSchema } from "./shared";

export const LinkSchema = LibraryItemBaseSchema.extend({
  title: z.string().optional(),
  name: z.string().optional(),
  websiteTitle: z.string().optional(),
  imageUrl: z.string().optional(),
  domain: z.string().optional(),
  url: z.string().optional(),
  path: z.string().optional(),
  legacy: z.boolean().optional(),
});

export type Link = z.infer<typeof LinkSchema>;

export const LinkPatchSchema = z.object({
  title: z.string().optional(),
  status: z.string().optional(),
});
