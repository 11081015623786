import { createContext, useState } from "react";

const FeedbackModalContext = createContext({
  showFeedbackModal: false,
  setShowFeedbackModal: (_show: boolean) => {},
});

const FeedbackModalProvider = ({ children }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  return (
    <FeedbackModalContext.Provider
      value={{ showFeedbackModal, setShowFeedbackModal }}
    >
      {children}
    </FeedbackModalContext.Provider>
  );
};

export { FeedbackModalContext, FeedbackModalProvider };
