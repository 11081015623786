import { SetStateAction } from "react";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { isObject, mapValues } from "lodash";

export type SetState<T> = React.Dispatch<SetStateAction<T>>;

export type ValueOf<T> = T[keyof T];

type MapType = Record<string, any>;

type DbDate = {
  seconds: number;
  nanoseconds: number;
  toDate: () => Date;
};

const isDate = (obj: any): obj is DbDate => {
  return !!(obj as DbDate).seconds;
};

const mapValuesDeep = (obj: MapType): MapType => {
  if (Array.isArray(obj)) return obj.map((item) => mapValuesDeep(item));
  // @TODO: remove the fallback when we figure out what is happening with some
  //        data that is checked as date that has `.seconds`
  if (isObject(obj) && isDate(obj))
    return obj?.toDate ? obj.toDate() : new Date();
  if (isObject(obj) && !isDate(obj))
    return mapValues(obj, (v) => mapValuesDeep(v));
  return obj;
};

export const firebaseDataMapper = (
  item: QueryDocumentSnapshot | DocumentData
) => {
  return {
    ...mapValuesDeep(item.data()),
    id: item.id,
    __refPath: item.ref.path,
    __parentId: item.ref.parent.parent?.id,
  };
};
