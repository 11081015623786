import { FC, useMemo } from "react";
import classNames from "classnames";
import Image from "next/legacy/image";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";
import { UserType } from "@lib/data/schemas/user";

import CircleIcon from "@components/Icons/CircleIcon";
import SmileIcon from "@components/Icons/SmileIcon";

export const objectSizes = {
  largest: { container: "h-20 w-20", text: "text-3xl", placeholder: "40" },
  large: { container: "h-16 w-16", text: "text-2xl", placeholder: "30" },
  medium: { container: "h-14 w-14", text: "text-xl", placeholder: "26" },
  default: { container: "h-10 w-10", text: "text-base", placeholder: "20" },
  small: { container: "h-9 w-9", text: "text-base", placeholder: "20" },
  smaller: { container: "h-8 w-8", text: "text-sm", placeholder: "18" },
  xsmall: { container: "h-6 w-6", text: "text-xs", placeholder: "16" },
  xxsmall: { container: "h-5 w-5", text: "text-xs", placeholder: "14" },
};

interface ClientAvatarProps {
  className?: string;
  client: Partial<ClientType | UserType | AccountType>;
  size?: keyof typeof objectSizes;
  preload?: boolean;
  disabled?: boolean;
  text?: string;
  customStyle?: any;
}

const getInitials = (
  client?: Partial<ClientType | UserType | AccountType>
): string => {
  let initials = "";

  if (!client) return initials;

  if (client.email) {
    initials = client.email.charAt(0);
  }

  if (client.firstName) {
    initials = client.firstName.charAt(0);
    if (client.lastName) {
      initials = `${initials}${client.lastName.charAt(0)}`;
    }
  }

  return initials;
};

const ClientAvatar: FC<ClientAvatarProps> = ({
  className,
  client,
  size = "default",
  preload = false,
  disabled = false,
  text,
  customStyle,
}) => {
  const fullName = displayNameFromContact(client);
  const initials = getInitials(client);
  const email = client?.email;
  const avatarURL = client?.avatarURL;
  const status = client && "status" in client ? client?.status : undefined;
  const clientColor = client && "color" in client ? client?.color : undefined;

  const avatarLink = useMemo(() => {
    if (avatarURL) return avatarURL;
    return undefined;
  }, [avatarURL]);

  const isDisabled = disabled || status === "archived";

  const containerSize = objectSizes[size].container;
  const textSize = objectSizes[size].text;
  const placeholderSize = objectSizes[size].placeholder;
  const colors = isDisabled
    ? "bg-grey-900 text-grey-500"
    : "bg-green-800 text-green-500";
  const style =
    clientColor && !isDisabled
      ? {
          backgroundColor: clientColor.background,
          color: clientColor.foreground,
        }
      : undefined;

  return (
    <div
      className={classNames(
        "relative flex shrink-0 mx-auto items-center justify-center font-medium rounded-full",
        containerSize,
        textSize,
        colors,
        className
      )}
      style={{
        ...style,
        ...customStyle,
      }}
    >
      {avatarLink && (
        <>
          <Image
            className={classNames(
              "invisible absolute mx-auto object-cover rounded-full",
              isDisabled && "grayscale",
              containerSize
            )}
            src={avatarLink}
            alt="Avatar"
            onLoad={(e) => (e.currentTarget.style.visibility = "visible")}
            onError={(e) => (e.currentTarget.style.visibility = "hidden")}
            layout="fill"
            priority={preload}
          />
        </>
      )}
      {["family", "company"].includes(
        client && "clientType" in client && client.clientType
          ? client.clientType
          : ""
      ) ? (
        <CircleIcon className="absolute h-2/5 w-2/5 text-blue-300  bg-blue-950 rounded-full p-0.5 right-0 bottom-0 border border-white" />
      ) : (
        <></>
      )}
      {fullName ? (
        <>{initials.toUpperCase()}</>
      ) : (
        <>
          {email ? (
            <>{email.charAt(0).toUpperCase()}</>
          ) : (
            <>
              {text ? (
                <>{text.toUpperCase()}</>
              ) : (
                <SmileIcon width={placeholderSize} height={placeholderSize} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClientAvatar;
