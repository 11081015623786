import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { GcalSchema, IntegrationsSchema } from "./integrations";
import { UserOnboardingSchema, UserUpgradeProSchema } from "./user";

export const AccountIntegrations = z.object({
  calendar: z.array(
    z.object({
      gcal: z.any(),
      gcalServerToken: z.any(),
    })
  ),
  email: z.array(
    z.object({
      gcal: z.any(),
      gcalServerToken: z.any(),
    })
  ),
});

export const AccountPublicItemType = z.array(
  z.object({ id: z.string(), publicOrder: z.number().optional() })
);

export const SectionTitlesSchema = z.object({
  packages: z.string().optional(),
  schedulers: z.string().optional(),
  members: z.string().optional(),
});

export const AccountSchema = BaseSchema.extend({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  avatarURL: z.string().nullable().optional(),
  integrations: IntegrationsSchema.optional(),
  timeZone: z.string().optional(),
  gcal: GcalSchema.optional(),
  gcalServerToken: z.any().optional(),
  zoom: z.any().optional(),
  onboarding: UserOnboardingSchema.optional(),
  upgradePro: UserUpgradeProSchema.optional(),
  secret: z.boolean().optional(),
  publicOrder: z.number().optional().nullable(),
  title: z.string().optional(),
  memberSchedulers: AccountPublicItemType.optional(),
  memberPackages: AccountPublicItemType.optional(),
  slug: z.string().optional(),
  bio: z.string().optional(),
  coachLocation: z.string().optional(),
  website: z.string().optional(),
  sectionTitles: SectionTitlesSchema.optional(),
  socialLinks: z
    .object({
      linkedin: z.string(),
      instagram: z.string(),
      twitter: z.string(),
    })
    .optional(),
  status: z.string().optional(),
});

export type AccountType = z.infer<typeof AccountSchema>;

export const AccessTypeSchema = z.enum(["limited", "elevated", "full"]);

export type AccessType = z.infer<typeof AccessTypeSchema>;

export const ExtendedAccountSchema = AccountSchema.extend({
  lastRefreshTime: z.date().optional(),
  accessType: AccessTypeSchema,
  isOwner: z.boolean(),
  permissions: z.object({
    canViewAllContacts: z.boolean(),
    canEditAllContacts: z.boolean(),
  }),
});

export type ExtendedAccountType = z.infer<typeof ExtendedAccountSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "accounts",
  deleteMode: "soft",
};
