import { FC, ReactElement } from "react";
import Head from "next/head";

import { useThemeClasses, useThemeCss } from "@contexts/theme";

import Favicons from "./Layout/Favicons";

interface Props {
  children?: ReactElement;
  data?: any;
}

export const SiteLayout: FC<Props> = ({ children, data }) => {
  const themeCss = useThemeCss();
  const themeClasses = useThemeClasses();
  const isCompany = data?.featureNames?.companyProfile;

  const favicon = isCompany ? data?.companyDetails?.favicon : undefined;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <Favicons favicon={favicon} />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/favicon-144.png" />
        <meta name="msapplication-config" content="/browserconfig.xml" />

        <style id="theme_variables">{themeCss}</style>
      </Head>
      <div className={themeClasses}>{children}</div>
    </>
  );
};
