import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9999 3.58573L7.93926 0.646371C8.13453 0.451109 8.45111 0.451109 8.64637 0.646371L9.35348 1.35348C9.54874 1.54874 9.54874 1.86532 9.35348 2.06058L5.35345 6.06061C5.15819 6.25588 4.8416 6.25587 4.64634 6.06061L0.646364 2.06058C0.451104 1.86532 0.451105 1.54874 0.646369 1.35347L1.35348 0.646373C1.54874 0.451112 1.86533 0.451114 2.06059 0.646378L4.9999 3.58573Z"
      />
    </svg>
  );
};

export default Icon;
