import { useEffect } from "react";
import axios from "axios";
import { useDocument } from "swr-firebase";

/**
 * @deprecated use useOrganization() and useAccount() hooks instead
 */
export default function useUser(uid) {
  const {
    data: user,
    update,
    isValidating,
  } = useDocument(uid ? `users/${uid}` : null, { listen: true });

  useEffect(() => {
    if (!user || !user.exists) return;

    const createReferralCode = async () => {
      if (user.isCoach && !user.referralCode) {
        try {
          const response = await axios.post(
            `/api/v1/users/${uid}/referral-code`
          );
          user.referralCode = response.data.referralCode;
        } catch (error) {
          console.log(error);
        }
      }
    };

    createReferralCode();
  }, [uid]);

  return {
    user,
    update,
    loading: uid && !user,
    isValidating,
  };
}
