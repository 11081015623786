import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { CouponDurationTypeEnum } from "./coupon";

export const SubscriptionPlanTierEnum = z.enum([
  "basic",
  "pro",
  "teams",
  "business",
]);

const SubscriptionPlanFrequencyEnum = z.enum(["month", "year", "lifetime"]);

export const SubscriptionPlanSchema = z.object({
  name: z.string(),
  priceId: z.string(),
  tier: SubscriptionPlanTierEnum,
  frequency: SubscriptionPlanFrequencyEnum,
});

export const SubscriptionCouponSchema = z.object({
  description: z.string(),
  promoCode: z.string().optional(),
  amountOff: z.number().optional(),
  currency: z.string().optional(),
  percentOff: z.number().optional(),
  duration: CouponDurationTypeEnum,
  durationInMonths: z.number().optional(),
  tier: SubscriptionPlanTierEnum,
  id: z.string(),
});

export type SubscriptionPlanTierType = z.infer<typeof SubscriptionPlanTierEnum>;

export type SubscriptionCouponType = z.infer<typeof SubscriptionCouponSchema>;

export type SubscriptionPlanType = z.infer<typeof SubscriptionPlanSchema>;

export type SubscriptionPlanFrequencyType = z.infer<
  typeof SubscriptionPlanFrequencyEnum
>;

export const schemaProperties: SchemaProperties = {
  collectionName: "subscriptionPlans",
  deleteMode: "soft",
};
