import { z } from "zod";

export const PromoCodeRedemptionValidationStatusEnum = z.enum([
  "valid",
  "expired",
  "usage-exceeded",
  "invalid",
]);
export type PromoCodeRedemptionValidationStatusEnumType = z.infer<
  typeof PromoCodeRedemptionValidationStatusEnum
>;
