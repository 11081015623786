import { z } from "zod";

import { ClientSchema } from "@lib/data/schemas/client";
import { TodoSchema } from "@lib/data/schemas/todo";

import { getSchemaDefinition } from "../common";

const tags = ["todos"];

const commonQueryDefinition = {
  path: z.object({ userId: z.string() }),
  query: z.object({
    clientId: z.string().optional(),
    accountId: z.string(),
    orgId: z.string(),
  }),
};

export const getClientsTodos = getSchemaDefinition(
  "/api/v1/users/{uid}/todos/clients",
  "get",
  commonQueryDefinition,
  {
    description: "Returns a list of todos from clients",
    tags,
  },
  z.array(
    z.object({
      clientId: z.string(),
      todos: z.array(TodoSchema),
      clientData: ClientSchema.extend({ assigneeId: z.string().optional() }),
    })
  )
);
