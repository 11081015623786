import React, { useCallback, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

import { useCallbackRef } from "@hooks/use-ref-callback";

import ClientOnlyPortal from "./ClientOnlyPortal";

export const ModalTitle = (props) => <div id="dialog-title" {...props} />;
export const ModalContent = (props) => (
  <div id="dialog-description" {...props} />
);

export const Modal = ({
  show,
  toggleShow,
  children,
  backdrop = true,
  maxWidth = "max-w-xl",
  bottom = false,
  clearDialogStyle = false,
  dialogClassName = "",
  dialogStyle = { maxHeight: "90%" },
  closeOnClickOrEscape = true,
}) => {
  const prevFocus = useRef();
  const [dialogRef, node] = useCallbackRef();

  useEffect(() => {
    if (show && node) {
      prevFocus.current = document.activeElement;
      node.focus();
    }
  }, [show, node]);

  const closeModal = useCallback(() => {
    if (closeOnClickOrEscape) {
      if (toggleShow) {
        toggleShow(false);
      }
      prevFocus.current?.focus();
    }
  }, [toggleShow]);

  const handleContentClick = useCallback((e) => e.stopPropagation(), []);
  const handleKeyDown = useCallback(
    (e) => (e.key === "Esc" || e.key === "Escape") && closeModal(),
    [closeModal]
  );

  return (
    <ClientOnlyPortal selector="#modal">
      <Transition
        show={show}
        enter="transform transition-all ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition-allease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={classNames(
          "z-50 outline-none fixed top-0 right-0 bottom-0 left-0 flex flex-col",
          backdrop ? " bg-black-ink/25" : " bg-black-ink/0"
        )}
        onMouseDown={closeModal}
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        <div
          role="dialog"
          id="modal-dialog"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          className={classNames(
            "outline-none",
            !clearDialogStyle &&
              `w-11/12 ${maxWidth} bg-white p-6 mx-auto mt-12 overflow-auto`,
            !clearDialogStyle &&
              (bottom ? "mt-auto rounded-t-3xl min-w-full" : "rounded-3xl"),
            dialogClassName
          )}
          onMouseDown={handleContentClick}
          ref={dialogRef}
          tabIndex={0}
          style={dialogStyle}
        >
          {children}
        </div>
      </Transition>
    </ClientOnlyPortal>
  );
};
