import z from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

export const TodoStatusSchema = z.enum(["incomplete", "complete", "deleted"]);

export const TodoSchema = BaseSchema.extend({
  title: z.string(),
  status: TodoStatusSchema,
  assigneeId: z.string().optional(),
  markedBy: z.string().optional(),
  dueAt: z.date().optional().nullable(),
  isTemplate: z.boolean().optional(),
});

export type TodoType = z.infer<typeof TodoSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "tasks",
  deleteMode: "soft",
};
