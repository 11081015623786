import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import classNames from "classnames";
import * as _ from "lodash";

import { Button } from "@components/Button";
import CautionIcon from "@components/Icons/CautionIcon";
import { Modal } from "@components/Modal";

import {
  Action,
  ActionDialogComponentProps,
  ActionDialogContextProps,
  ActionDialogSupportedIcons,
  ShowMethodProps,
} from "./types";

export * from "./types";

const supportedIconsMap: {
  [key in ActionDialogSupportedIcons]?: React.ReactElement;
} = {
  caution: (
    <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
      <CautionIcon />
    </div>
  ),
};

const KIND_MAP = {
  danger: "bg-red-100 text-red-600",
  warning: "bg-action-900 text-action-500",
};

const ActionDialogComponent: FC<ActionDialogComponentProps> = ({
  title,
  message,
  actions,
  icon = "caution",
  onActionClick,
  show,
  setShow,
  kind = "danger",
}) => {
  const onActionClickInternal = (action: Action) => {
    setShow(false);
    onActionClick(action);
  };

  const iconToRender = _.isString(icon)
    ? supportedIconsMap[icon as ActionDialogSupportedIcons]
    : icon;

  return (
    <Modal show={show} toggleShow={setShow} maxWidth="max-w-md">
      <div className="mt-6 mb-6 text-center flex flex-col items-center">
        <div
          className={classNames(
            "mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-16 sm:w-16",
            KIND_MAP[kind]
          )}
        >
          {iconToRender}
        </div>
        <div className={"text-2xl font-medium leading-tight mt-4"}>{title}</div>
      </div>
      <div className="w-full flex flex-col md:max-w-sm">
        {message}
        <div className="w-full flex flex-col">
          {actions.map((action) => (
            <Button
              key={action.value}
              className="mt-2"
              primary={action.primary}
              peach={action.peach}
              onClick={() => onActionClickInternal(action)}
            >
              {action.text}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export const ActionDialogContext = createContext<ActionDialogContextProps>({
  show: () => undefined,
});

export const ActionDialogProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dialogProps, setDialogProps] = useState<ActionDialogComponentProps>();
  const [showDialog, setShowDialog] = useState(false);

  const show = useCallback(
    ({ title, message, actions, icon, kind }: ShowMethodProps) => {
      return new Promise<number | string>((resolve) => {
        const onActionClick = (action: Action): void => resolve(action?.value);
        setDialogProps({
          title,
          message,
          actions,
          icon,
          onActionClick,
          setShow: setShowDialog,
          kind,
        });
        setShowDialog(true);
      });
    },
    []
  );

  return (
    <>
      <ActionDialogContext.Provider value={{ show }}>
        {children}
      </ActionDialogContext.Provider>
      {dialogProps && (
        <ActionDialogComponent show={showDialog} {...dialogProps} />
      )}
    </>
  );
};
