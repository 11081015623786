import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { AscDesc, EventHandler, StreamChat } from "stream-chat";
import useSWR from "swr";

import { displayNameFromContact } from "@lib/contacts";
import { UserType } from "@lib/data/schemas/user";
import fetchJSON from "@lib/fetch-json";
import { compatDB as db } from "@lib/firebase-config";
import {
  ChannelType,
  ConnectionType,
  StreamChatGenerics,
} from "@lib/shared-types";

import { useAuth } from "./auth";

export const client = new StreamChat<StreamChatGenerics>(
  process.env.stream_api_key as string,
  {
    timeout: 6000,
  }
);

export const MessagingContext = createContext<{
  channels?: ChannelRecordType;
  connection?: ConnectionType;
  error?: any;
  queryChannel: (id: string) => Promise<void>;
}>({
  queryChannel: async () => {},
});

export const MessagingProvider = ({ children }: { children: ReactNode }) => {
  const { account, oid } = useAuth();
  const { connection, error } = useChatConnection(account);

  const { channels, queryChannel } = useChannels({
    connection,
    uid: oid,
  });

  return (
    <MessagingContext.Provider
      value={{ channels, queryChannel, connection, error }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

const sharedOptions = {
  watch: true,
  state: true,
  message_limit: 1,
  presence: true,
};

type ChannelRecordType = Record<string, ChannelType>;
type UseChannelsType = (props: {
  connection?: ConnectionType;
  uid: string | undefined;
}) => {
  channels?: ChannelRecordType;
  queryChannel: (id: string) => Promise<void>;
};
const useChannels: UseChannelsType = ({ connection, uid }) => {
  const [channels, setChannels] = useState<ChannelRecordType>({});

  const queryChannel = useCallback(async (id: string) => {
    const filter = { id };
    const [channel] = await client.queryChannels(filter, {}, sharedOptions);

    if (channel)
      setChannels((channels) => ({
        ...channels,
        [channel.id as string]: channel,
      }));
  }, []);

  const queryChannels = useCallback(async () => {
    if (!uid) return;

    const { docs: deletedContacts } = await getDocs(
      query(
        collection(db, `users/${uid}/clients`),
        where("status", "==", "deleted")
      )
    );

    const filter: { members: { $in: string[] }; id?: any } = {
      members: { $in: [uid] },
    };
    if (deletedContacts.length)
      filter.id = { $nin: deletedContacts.map((doc: any) => doc.id) };

    const sort = [{ unread_count: -1 as AscDesc }];
    const options = { ...sharedOptions };
    const channels = await client.queryChannels(filter, sort, options);

    setChannels(
      channels.reduce(
        (prev, current) => ({ ...prev, [current.id as string]: current }),
        {}
      )
    );
  }, [uid]);

  useEffect(() => {
    if (connection?.me?.unread_channels) queryChannels();

    const handler: EventHandler<StreamChatGenerics> = ({
      total_unread_count,
      channel_id,
    }) => {
      if (total_unread_count !== undefined && channel_id)
        queryChannel(channel_id);
    };
    client.on(handler);

    return () => client.off(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return { channels, queryChannel };
};

type UseChatConnectionType = (account: UserType | undefined) => {
  connection?: ConnectionType;
  error: any;
};
const useChatConnection: UseChatConnectionType = (account) => {
  // TODO probably good idea to rename API
  const { data, error } = useSWR(
    account ? `/api/v1/users/${account.id}/stream-token` : null,
    fetchJSON,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );
  const [connection, setConnection] = useState<ConnectionType>();

  useEffect(() => {
    if (!account) return;

    // @TODO: review type
    // @ts-expect-error: since the update this type is failing because the prop
    //                   `connecting` do not exists
    if (data?.userToken && !client.connecting) {
      client
        .connectUser(
          {
            id: account.id,
            name: displayNameFromContact(account) || "",
            image: account.avatarURL || "",
          },
          data.userToken
        )
        .catch((error) => {
          console.log(error);
          // TODO -- if this method fails we should gracefully retry.
          // for now throw the error. hopefully user will refresh.
          throw error;
        })
        .then((response) => {
          setConnection(response as ConnectionType);
        });
    }
  }, [data, account]);

  return { connection, error };
};
