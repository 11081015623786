import { z } from "zod";

/*
 * Abstraction for digital signature stored in our form document for digital signature components.
 */

export enum DigitalSignatureService {
  DropboxSign = "DROPBOX_SIGN",
}

export enum DropboxSignerRoles {
  Client = "Client",
}

export const DigitalSignatureServiceDataSchema = z.object({
  createdDate: z.date(),
  updatedDate: z.date(),
});
export type DigitalSignatureServiceData = z.infer<
  typeof DigitalSignatureServiceDataSchema
>;

export const DropboxSignDataSchema = DigitalSignatureServiceDataSchema.extend({
  templateId: z.string(),
  editUrl: z.string(),
  expiresAt: z.date().optional(),
});
export type DropboxSignData = z.infer<typeof DropboxSignDataSchema>;

export type DigitalSignatureTemplate = {
  serviceType: DigitalSignatureService;
  serviceData: DigitalSignatureServiceData;
  dateCreated: Date;
  dateUpdated: Date;
  createdUserId: string;
  updatedUserId: string;
  templateFileId: string;
  signatures: Array<DigitalSignature>;
};

export const DigitalSignatureSchema = z.object({
  userId: z.string(),
  dateSigned: z.date(),
  signedFileId: z.string(),
});
export type DigitalSignature = z.infer<typeof DigitalSignatureSchema>;
