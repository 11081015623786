import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import { differenceInCalendarDays } from "date-fns";
import { capitalize } from "lodash";
import moment from "moment";
import Stripe from "stripe";

import analytics from "@lib/analytics";
import {
  SubscriptionPlanTierType,
  SubscriptionPlanType,
} from "@lib/data/schemas/subscription-plan";

import PlusIcon from "@components/Icons/PlusIcon";
import {
  FeaturesByTypeType,
  StripeInvoiceType,
  StripeSubscriptionType,
  SubscriptionPlanInvoiceType,
} from "@components/Plans/types";

import pluralHelper from "../pluralHelper";

export const frequencyString: Record<string, string> = {
  month: "Monthly",
  year: "Yearly",
};

export const featuresByType: FeaturesByTypeType = {
  basic: [
    { value: "2 contact limit" },
    { value: "1000MB of storage" },
    { value: "Limited workflows" },
    { value: "Email support only" },
  ],
  pro: [
    { value: "Unlimited contacts & storage" },
    { value: "Contracts & digital signatures" },
    { value: "Zapier integration" },
    { value: "Custom domain + branding" },
    { value: "Priority support" },
  ],
  teams: [
    { value: "Everything in Pro" },
    { value: "Round robin scheduling" },
    { value: "Real-time session tracking" },
    { value: "Internal team activity log" },
    { value: "Team profiles" },
    {
      value: "Includes three (3) seats",
      icon: PlusIcon,
      highlighted: true,
    },
  ],
};

export const subscriptionPlanDescriptionStrings = {
  basic: "For those at the very start of their business.",
  pro: "More power, more customization and integrations.",
  teams: "For business of 2+ in need of collaboration and client sharing.",
};

export const getStripeSubscriptionTier = (
  subscription: Stripe.Subscription
): SubscriptionPlanTierType =>
  (subscription?.items?.data[0]?.plan?.metadata
    ?.tier as SubscriptionPlanTierType) || "basic";

export const isSubscriptionBasic = (
  subscription: Stripe.Subscription
): boolean => !!getStripeSubscriptionTier(subscription).includes("basic");

export const isSubscriptionPro = (subscription: Stripe.Subscription): boolean =>
  !!getStripeSubscriptionTier(subscription).includes("pro");

export const isSubscriptionTeams = (
  subscription: Stripe.Subscription
): boolean => !!getStripeSubscriptionTier(subscription).includes("teams");

export const isDowngradeSubscriptionPlan = (
  currentPlan: SubscriptionPlanType,
  targetPlan: SubscriptionPlanType
): boolean => currentPlan.tier === "pro" && targetPlan.tier === "basic";

export const isSameSubscriptionPlanTier = (
  currentPlan: SubscriptionPlanType,
  targetPlan: SubscriptionPlanType
): boolean => currentPlan.tier === targetPlan.tier;

export const isFrequencyChange = (
  currentPlan: SubscriptionPlanType,
  targetPlan: SubscriptionPlanType
): boolean => {
  return (
    isSameSubscriptionPlanTier(currentPlan, targetPlan) &&
    currentPlan.frequency !== targetPlan.frequency
  );
};

export const getFrequencyFromSubscription = (
  subscription: Stripe.Subscription
): Stripe.Price.Recurring.Interval =>
  subscription?.items?.data[0].price.recurring?.interval || "month";

// Translates the stripe subscription data into props that the
// `SubscriptionCreditCard` accepts
export const translateDataToCreditCardProps = (
  subscription: StripeSubscriptionType,
  upcomingInvoice: StripeInvoiceType
) => ({
  frequency: getFrequencyFromSubscription(subscription),
  nextInvoiceIssueDate: moment
    .unix(upcomingInvoice.next_payment_attempt || 0)
    .toDate(),
  // @ts-expect-error: the errot from missing card prop cames from stripe type
  lastNumbers: subscription?.default_payment_method?.card?.last4 || "not-found",
});

// Translates the stripe subscription data into props that the
// `SubscriptionPlanCard` accepts
export const translateDataToPlanProps = (
  subscription: StripeSubscriptionType,
  subscriptionPlan: SubscriptionPlanType | null,
  upcomingInvoice: StripeInvoiceType | null
) => {
  const subscriptionItem = subscription?.items?.data[0];
  const subscriptionPrice = subscriptionItem?.price?.unit_amount;
  const upcomingInvoiceTotal = upcomingInvoice?.total;
  let originalPrice;
  let price;
  if (upcomingInvoiceTotal && upcomingInvoiceTotal !== subscriptionPrice) {
    // A promocode was used
    originalPrice = subscriptionPrice;
    price = upcomingInvoiceTotal || 0;
  } else {
    // No promocode used
    price = subscriptionPrice || 0;
  }
  const frequency = getFrequencyFromSubscription(subscription);
  const now = Math.floor(Date.now() / 1000);
  const isInFreeTrial =
    (subscription &&
      subscription.trial_start &&
      subscription.trial_end &&
      subscription.trial_start < now &&
      subscription.trial_end > now) ||
    false;

  return {
    frequency,
    price: price / 100,
    ...(originalPrice && {
      originalPrice: originalPrice / 100,
    }),
    priceByMonth:
      frequency === "month"
        ? price / 100
        : Number((price / 12 / 100).toFixed(2)),
    currency: subscriptionItem?.price.currency,
    title: subscriptionPlan?.name || "",
    description: subscriptionPlan?.tier
      ? subscriptionPlanDescriptionStrings[subscriptionPlan.tier]
      : "",
    priceId: subscriptionItem?.price.id,
    isInFreeTrial,
  };
};

export const getSavedAmountInPercentage = (
  monthlySubscription?: ExtendedStripeSubscriptionPlan,
  yearlySubscription?: ExtendedStripeSubscriptionPlan
): string => {
  if (!monthlySubscription || !yearlySubscription) return "";
  const totalMonthlyInAnYear = monthlySubscription.amount * 12;
  const totalYearly = yearlySubscription.amount;
  const percentage =
    ((totalMonthlyInAnYear - totalYearly) / totalMonthlyInAnYear) * 100;
  return `${Math.round(percentage)}%`;
};

export const translateToSubscriptionPlan = (
  plan: Stripe.Plan
): ExtendedStripeSubscriptionPlan => ({
  tier: (plan.metadata?.tier || "basic") as SubscriptionPlanType["tier"],
  priceId: plan.id,
  name: capitalize(plan.metadata?.tier || "basic"),
  frequency: plan.interval as SubscriptionPlanType["frequency"],
  amount: plan.amount || 0,
  currency: plan.currency,
});

export const getTrialRemainingStatus = (
  subscription?: StripeSubscriptionType
): { text: string; hasEnded: boolean } | null => {
  const endTimestamp = subscription?.trial_end;

  if (!endTimestamp) return null;

  const trialDaysRemaining = differenceInCalendarDays(
    new Date(endTimestamp * 1000),
    new Date()
  );

  if (trialDaysRemaining < 0) {
    return { text: "has ended", hasEnded: true };
  }

  if (trialDaysRemaining === 0) {
    return { text: "ends today", hasEnded: false };
  }

  return {
    text: `ends in ${pluralHelper(trialDaysRemaining, "day", true)}`,
    hasEnded: false,
  };
};

// function to reuse properties from all events
export const track = (
  event: string,
  plan?: SubscriptionPlanType,
  properties?: any
): void => {
  if (!plan) return;
  analytics.track({
    event,
    properties: {
      ...(properties && { ...properties }),
      subscriptionFrequency: plan.frequency,
      subscriptionTier: plan.tier,
    },
  });
};

// Used in storybook and unit tests
export const mockSubscriptionInvoice = (
  id: number
): SubscriptionPlanInvoiceType => ({
  id: `stripe_invoice_${id}`,
  paidAt: new Date(),
  amount: 30,
  invoicePdf: "https://app.practice.do",
  currency: "USD",
  subscription: {
    title: "Basic Plan",
    frequency: "month",
  },
});

export const mockSubscriptionPlan = (
  id: number,
  frequency: SubscriptionPlanType["frequency"] = "month",
  tier: SubscriptionPlanType["tier"] = "basic"
): SubscriptionPlanType & { amount: number; currency: string } => {
  const isPro = tier === "pro";
  return {
    priceId: `price_id_${id}`,
    name: isPro ? "Pro" : "Basic",
    amount: 30,
    currency: "USD",
    frequency,
    tier,
  };
};
