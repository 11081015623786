import { useCallback, useEffect, useState } from "react";
import { isValid, parseISO } from "date-fns";
import { destroyCookie, parseCookies, setCookie } from "nookies";

class CookieStorage {
  maxAge?: number;

  constructor(isSession: boolean) {
    if (!isSession) {
      this.maxAge = 30 * 24 * 60 * 60; // 30 days in seconds
    }
  }

  getItem(key: string): any {
    const cookies = parseCookies();
    return cookies[key];
  }

  setItem(key: string, value: any): void {
    const stringValue =
      typeof value === "string" ? value : JSON.stringify(value);
    setCookie(null, key, stringValue, {
      path: "/",
      maxAge: this.maxAge,
    });
  }

  removeItem(key: string): void {
    destroyCookie(null, key, {
      path: "/",
    });
  }
}

export const getStorage = (storageName: "sessionStorage" | "localStorage") => {
  try {
    return window[storageName];
  } catch (e) {
    return new CookieStorage(storageName === "sessionStorage");
  }
};

const usePersistentState = <
  T extends Record<string, any> | boolean | string | string[] | Date | null,
>(
  key: string,
  initialValue?: T,
  isSession = false
): {
  persistentValue: T;
  persistentSetValue: (value: T) => void;
  persistentClear: () => void;
} => {
  const isBrowser = process.browser;
  const storage = isBrowser
    ? isSession
      ? getStorage("sessionStorage")
      : getStorage("localStorage")
    : undefined;
  const [value, setValue] = useState<T>(() => {
    let value = isBrowser
      ? storage?.getItem(key) && storage.getItem(key) !== "undefined"
        ? JSON.parse(storage.getItem(key))
        : initialValue
      : undefined;

    // Fun Fact: Boolean returns 'true' as a valid date
    if (value !== true && value !== false) {
      if (value && isValid(new Date(value))) value = parseISO(value);
    }

    return value;
  });

  useEffect(() => {
    if (key && isBrowser) {
      const newValue =
        value instanceof Date && isValid(value) ? value.toISOString() : value;
      storage?.setItem(key, JSON.stringify(newValue));
    }
  }, [value, key, isBrowser, storage]);

  const persistentClear = () => isBrowser && storage?.removeItem(key);

  const persistentSetValue = useCallback(
    async (val: T) => {
      setValue(val);
      await new Promise((resolve) => setTimeout(resolve, 0));
    },
    [setValue]
  );

  return {
    persistentValue: value,
    persistentSetValue,
    persistentClear,
  };
};

export default usePersistentState;
