import { Tier } from "@practice/authz";
import { z } from "zod";

import { getSchemaDefinition } from "../common";

const PathSchema = z.object({
  orgId: z.string(),
});

const QuerySchema = z.object({
  featureName: z.string(),
});

export const hasFeature = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/authorization/has-feature",
  "get",
  {
    path: PathSchema,
    query: QuerySchema,
  },
  {
    description: "Check if a user has access to a specific feature.",
    tags: ["authorization"],
  },
  z.object({ authorized: z.boolean() })
);

export const getTier = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/authorization/tier",
  "get",
  {
    path: PathSchema,
  },
  {
    description: "Get tier for organization.",
    tags: ["authorization"],
  },
  z.custom<Tier>()
);
