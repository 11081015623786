import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";

import { app } from "@lib/firebase-config";

let analytics: {
  track: (...args: any[]) => void;
  identify: (userId: string, props?: any) => void;
} = {};

// utils
export const sharedProps = {
  environment: process.env.PRACTICE_ENV,
};

// main setup
if (process.env.PRACTICE_ENV === "production") {
  if (typeof window === "undefined") {
    // not in the browser
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Analytics = require("analytics-node");
    analytics = new Analytics("e4PiYyiedjP9TZsSyyQn2rtQEeghamMy");
  } else {
    // we're in the browser
    const firebaseAnalytics = getAnalytics(app);
    analytics = {
      track: (...args) => {
        // This is a shim between the google calls which are {event, userId, properties} and heap which requires event, properties
        const arg = args[0];
        let eventName, eventProperties;
        if (typeof arg === "string") {
          eventName = arg;
        } else if (arg?.event) {
          eventName = arg?.event;
          eventProperties = arg?.properties;
        }

        if (eventName) {
          window.heap.track(eventName, eventProperties);
          logEvent(firebaseAnalytics, ...args);
        } else {
          console.error("Invalid Event:", eventName, args);
        }
      },
      identify: (userId, userInfo) => {
        // Ignore anon users
        if (userId) {
          window.heap.identify(userId);
          window.heap.addUserProperties(userInfo);
        }
        setUserId(firebaseAnalytics, userId);
        setUserProperties(firebaseAnalytics, userInfo);
      },
    };
  }
} else {
  console.log(
    `${process.env.PRACTICE_ENV} enviroment - not sending analytics. events will be logged in console.`
  );
  analytics.identify = (userId, props) => {
    console.log("analytics.identify", userId, props);
  };
  analytics.track = (...args) => {
    console.log("analytics.track", ...args);
  };
}

export default analytics;
