import { useMemo } from "react";
import {
  ExtendedStripeSubscriptionPlan,
  getStripeSubscription,
  getStripeSubscriptionUpcomingInvoice,
} from "api-services/definitions/stripe";
import { useApiGet } from "api-services/endpoints";
import Stripe from "stripe";

import { translateToSubscriptionPlan } from "@lib/utils/subscription-plans";

interface StripeData {
  plan: ExtendedStripeSubscriptionPlan | null;
  subscription: Stripe.Subscription | undefined;
  membersSubscription:
    | (Stripe.Subscription & { plan?: ExtendedStripeSubscriptionPlan })
    | undefined;
  upcomingInvoice: Stripe.Invoice | undefined;
  customer: Stripe.Customer | undefined;
  membersUpcomingInvoice: Stripe.Invoice | undefined;
  isLoadingSubscription: boolean;
  isLoadingUpcomingInvoice: boolean;
}

export default function useStripeInfo(userId: string | undefined): StripeData {
  const { data, isLoading: isLoadingSubscription } = useApiGet(
    userId ? getStripeSubscription : undefined,
    { userId: userId ?? "" },
    {},
    { revalidateOnFocus: false }
  );
  const customer = data?.customer;
  let subscription = data?.subscription;
  const membersSubscription = data?.membersSubscription;

  if (subscription?.status === "no_subscription") {
    subscription = undefined;
  }

  const { data: upcomingInvoiceResponse, isLoading: isLoadingUpcomingInvoice } =
    useApiGet(
      getStripeSubscriptionUpcomingInvoice,
      { subscriptionId: subscription?.id, userId: userId ?? "" },
      {},
      { revalidateOnFocus: false }
    );

  const plan = useMemo(() => {
    const plan = subscription?.items?.data[0].plan;
    return plan ? translateToSubscriptionPlan(plan) : null;
  }, [subscription?.items?.data]);

  return {
    // TODO: Add connected account and customer info.
    //account,
    customer,
    plan,
    subscription,
    membersSubscription,
    membersUpcomingInvoice: upcomingInvoiceResponse?.membersUpcomingInvoice,
    upcomingInvoice:
      !["canceled", "unpaid"].includes(subscription?.status) &&
      upcomingInvoiceResponse?.upcomingInvoice,
    isLoadingSubscription,
    isLoadingUpcomingInvoice,
  };
}
