import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const InfoIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12ZM20.5 12C20.5 16.4183 16.9183 20 12.5 20C8.08172 20 4.5 16.4183 4.5 12C4.5 7.58172 8.08172 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12ZM11.5 10.5C11.5 10.2239 11.7239 10 12 10H13C13.2761 10 13.5 10.2239 13.5 10.5V16.5C13.5 16.7761 13.2761 17 13 17H12C11.7239 17 11.5 16.7761 11.5 16.5V10.5ZM12 7C11.7239 7 11.5 7.22386 11.5 7.5V8.5C11.5 8.77614 11.7239 9 12 9H13C13.2761 9 13.5 8.77614 13.5 8.5V7.5C13.5 7.22386 13.2761 7 13 7H12Z"
      />
    </svg>
  );
};

export default InfoIcon;
