import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

import { getApps, initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_DATABASE_URL,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MEASUREMENT_ID,
  messagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_STORAGE_BUCKET,
};

let app, compatApp: firebase.app.App | undefined;
if (getApps()) {
  app = initializeApp(firebaseConfig);
  compatApp = firebase.initializeApp(firebaseConfig, "compat");
}

if (!app || !compatApp) {
  throw new Error("Firebase initialization error!");
}

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const compatAuth = compatApp.auth();
const db = getFirestore(app);
const compatDB = compatApp.firestore();
const functions = getFunctions(app);
const compatFunctions = compatApp.functions();
const storage = getStorage(app);
const compatStorage = compatApp.storage();

if (process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR === "true") {
  connectAuthEmulator(
    auth,
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST ||
      "http://localhost:9099"
  );
  compatAuth.useEmulator(
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST ||
      "http://localhost:9099"
  );

  const firestoreParts = (
    process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST || "localhost:8080"
  ).split(":");
  const firestorePort = parseInt(firestoreParts[1], 10);
  connectFirestoreEmulator(db, firestoreParts[0], firestorePort);
  compatDB.useEmulator(firestoreParts[0], firestorePort);

  const storageParts = (
    process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST || "localhost:9199"
  ).split(":");
  const storagePort = parseInt(storageParts[1], 10);
  connectStorageEmulator(storage, storageParts[0], storagePort);
  compatStorage.useEmulator(storageParts[0], storagePort);

  const functionsParts = (
    process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST || "localhost:5001"
  ).split(":");
  const functionsPort = parseInt(functionsParts[1], 10);
  connectFunctionsEmulator(functions, functionsParts[0], functionsPort);
  compatFunctions.useEmulator(functionsParts[0], functionsPort);
}

export {
  app,
  auth,
  compatApp,
  compatAuth,
  compatDB,
  compatFunctions,
  compatStorage,
  db,
  functions,
  googleProvider,
  storage,
};
