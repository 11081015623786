import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { noop } from "lodash";

import { UserFontEnumType, UserType } from "@lib/data/schemas/user";
import { SanitizedUserType } from "@lib/shared-types";
import isDarkMode from "@lib/utils/dark-mode";

interface ThemeColor {
  accent: string;
  foreground: string;
  background: string;
}

export const DEFAULT_LIGHT_COLOR: ThemeColor = {
  accent: "#f1c27d",
  foreground: "#040506",
  background: "#ffffff",
};

export const DEFAULT_DARK_COLOR: ThemeColor = {
  accent: "#CC8033",
  foreground: "#ffffff",
  background: "#040506",
};

interface Theme {
  color: UserType["color"];
  font: UserFontEnumType;
  isDynamicTheme: boolean;
}

const DEFAULT_LIGHT_THEME: Theme = {
  color: DEFAULT_LIGHT_COLOR,
  font: "Modern",
  isDynamicTheme: false,
};

const DEFAULT_DARK_THEME: Theme = {
  color: DEFAULT_DARK_COLOR,
  font: "Modern",
  isDynamicTheme: false,
};

export const getRGB = (hex: string): [number, number, number] => {
  let color = hex.replace(/#/g, "");

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return [r, g, b];
};

export const getRGBColor = (hex: string) => {
  return `${getRGB(hex).join(`, `)};`;
};

const rgbToLightness = (hex: string) => {
  let [r, g, b] = getRGB(hex);

  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  return (max + min) / 2;
};

const darkenRGB = (rgb: [number, number, number], darkenFactor: number) => {
  const [r, g, b] = rgb;
  return [r - r * darkenFactor, g - g * darkenFactor, b - b * darkenFactor];
};

interface ThemeContextProps {
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: DEFAULT_LIGHT_THEME,
  setTheme: noop,
});

const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(DEFAULT_LIGHT_THEME);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  return useContext(ThemeContext);
};

/**
 *
 * @remarks
 * If `onlyThemedIfHasWhiteLabel` is set to `true`, `coachUser.featureNames.whiteLabel` must exist and be `true` to enable the custom theme.
 *
 * This `featureNames` object should be added to the coach object in `getServerSideProps`.
 */
export const useCustomOrDarkTheme = (
  coachUser?: Partial<UserType> & { featureNames?: { whiteLabel: boolean } },
  onlyThemedIfHasWhiteLabel = false
): void => {
  const { setTheme } = useTheme();
  const hasWhiteLabel = coachUser?.featureNames?.whiteLabel;

  useEffect(() => {
    if (coachUser && (hasWhiteLabel || !onlyThemedIfHasWhiteLabel)) {
      setTheme({
        ...DEFAULT_DARK_THEME,
        ...(coachUser.color && { color: coachUser.color }),
        ...(coachUser.font && { font: coachUser.font }),
        ...(coachUser.isDynamicTheme && {
          isDynamicTheme: coachUser.isDynamicTheme,
        }),
      });
    } else {
      setTheme(DEFAULT_DARK_THEME);
    }

    return () => setTheme(DEFAULT_LIGHT_THEME);
  }, [coachUser, hasWhiteLabel, onlyThemedIfHasWhiteLabel, setTheme]);
};

export const useDarkTheme = (): void => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme(DEFAULT_DARK_THEME);

    return () => setTheme(DEFAULT_LIGHT_THEME);
  }, [setTheme]);
};

export const useLightThemeWithAccent = (coachUser: SanitizedUserType): void => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme({
      ...DEFAULT_LIGHT_THEME,
      color: {
        ...DEFAULT_LIGHT_THEME.color!,
        ...(coachUser.color?.accent && { accent: coachUser.color?.accent }),
      },
    });

    return () => setTheme(DEFAULT_LIGHT_THEME);
  }, [coachUser, setTheme]);
};

export const useThemeCss = (): string => {
  const { theme } = useTheme();

  if (typeof window !== "undefined" && theme.isDynamicTheme) {
    theme.color = isDarkMode() ? DEFAULT_DARK_COLOR : DEFAULT_LIGHT_COLOR;
  }

  const { accent, foreground, background } = theme.color!;
  const error = "#C35560";
  const buttonText =
    rgbToLightness(accent) <= 0.5
      ? DEFAULT_DARK_COLOR.foreground
      : DEFAULT_LIGHT_COLOR.foreground;

  return `
    :root {
        --color-accent: ${getRGBColor(accent)};
        --color-foreground: ${getRGBColor(foreground)};
        --color-foreground-hex: ${foreground};
        --color-accent-90: ${darkenRGB(getRGB(accent), 0.1).join(", ")};;
        --color-accent-80: ${darkenRGB(getRGB(accent), 0.2).join(", ")};;
        --color-background: ${getRGBColor(background)};
        --color-background-hex: ${background};
        --color-error: ${getRGBColor(error)};
        --color-button-text: ${getRGBColor(buttonText)};
      }
    `;
};

const FONTS_MAP = {
  Modern: "font-roboto",
  Classic: "font-crimson",
  Rounded: "font-rubik",
};

export const useThemeClasses = () => {
  const { theme } = useTheme();

  const font = theme.font;

  return FONTS_MAP[font || "Modern"];
};

export { ThemeContext, ThemeProvider };
