import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.0274 12.3922C10.0079 12.3454 9.98374 12.3007 9.95545 12.2595C9.8505 12.1065 9.689 12 9.5 12H8.5C8.22386 12 7.99672 12.225 8.03082 12.499C8.09513 13.0157 8.25799 13.5019 8.5 13.938C8.51144 13.9586 8.52307 13.9791 8.53486 13.9995C8.53495 13.9997 8.53504 13.9998 8.53513 14C8.8406 14.5281 9.26333 14.9798 9.7677 15.3197C10.4053 15.7492 11.1734 16 12 16C12.8266 16 13.5947 15.7492 14.2323 15.3197C14.7367 14.9798 15.1594 14.5281 15.4649 14C15.465 13.9998 15.465 13.9997 15.4651 13.9995C15.4769 13.9791 15.4886 13.9586 15.5 13.938C15.742 13.5019 15.9049 13.0157 15.9692 12.499C16.0033 12.225 15.7761 12 15.5 12H14.5C14.311 12 14.1495 12.1065 14.0445 12.2595C14.0163 12.3007 13.9921 12.3454 13.9726 12.3922C13.9614 12.419 13.9518 12.4465 13.9438 12.4745C13.9419 12.4813 13.94 12.4881 13.9383 12.4949C13.8348 12.9015 13.6067 13.2583 13.2971 13.5224C12.9479 13.8202 12.4949 14 12 14C11.5051 14 11.0521 13.8202 10.7029 13.5224C10.3933 13.2583 10.1652 12.9015 10.0617 12.4949C10.06 12.4881 10.0581 12.4813 10.0562 12.4745C10.0482 12.4465 10.0386 12.419 10.0274 12.3922ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      />
    </svg>
  );
};

export default Icon;
