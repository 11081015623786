import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import { SchedulerSchema } from "@lib/data/schemas/scheduler";

const tags = ["scheduler"];

const BasePathSchema = z.object({
  apiVersion: z.string(),
  userId: z.string(),
});

const SchedulerCommonPathSchema = BasePathSchema.merge(
  z.object({
    schedulerId: z.string(),
  })
);

const SchedulerCommonResponseSchema = z.object({
  status: z.string(),
});

const UpdateSchedulerSchema = SchedulerSchema.partial();

export const putUpdateScheduler = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/schedulers/{schedulerId}",
  "put",
  {
    path: SchedulerCommonPathSchema,
    body: UpdateSchedulerSchema,
  },
  {
    description: "Updates scheduler",
    tags,
  },
  SchedulerCommonResponseSchema
);

export const deleteScheduler = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/schedulers/{schedulerId}",
  "delete",
  {
    path: SchedulerCommonPathSchema,
  },
  {
    description: "Deletes a scheduler",
    tags,
  },
  SchedulerCommonResponseSchema
);

export const getSchedulers = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/schedulers",
  "get",
  {
    path: BasePathSchema,
    query: z.object({
      availabilityId: z.string().optional(),
    }),
  },
  {
    description: "Gets schedulers",
    tags,
  },

  // @TODO: we need to not validate smartActions here because too many data
  //        with inconsistent types are being returned. In order to use the get
  //        with schema validation, we need to fix the data first.
  SchedulerSchema.extend({
    smartActions: z.array(z.any()).optional(),
  }).array()
);
