import { z } from "zod";

import {
  SmartActionActionSchema,
  SmartActionEventSchema,
} from "./smart-action";

export const SmartActionTemplateWhenSchema = z.object({
  value: z.number(),
  type: z.enum(["hours", "days"]),
});
export type SmartActionTemplateWhenType = z.infer<
  typeof SmartActionTemplateWhenSchema
>;

export const SmartActionTemplateEventSchema = SmartActionEventSchema.omit({
  resourceId: true,
}).extend({
  when: SmartActionTemplateWhenSchema.optional(),
});

export const SmartActionTemplateSchema = z.object({
  event: SmartActionTemplateEventSchema,
  action: SmartActionActionSchema,
});
export type SmartActionTemplateType = z.infer<typeof SmartActionTemplateSchema>;
