import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import { CouponSchema } from "@lib/data/schemas/coupon";
import { PromoCodeRedemptionValidationStatusEnum } from "@lib/models/coupons/types";
import { ZodDateAsString } from "@lib/utils/zod-schemas";

const tags = ["coupons"];

const CouponCommonPathSchema = z.object({
  apiVersion: z.string(),
  userId: z.string(),
});

/* /api/{apiVersion}/users/{userId}/coupons/promotion-codes/validate-redemption                  */

const CouponValidatePromoCodeRedemptionPayloadSchema = z.object({
  promotionCode: z.string(),
  packageId: z.string().optional(),
});
const CouponValidatePromoCodeRedemptionPostResponseSchema = z.object({
  promotionCode: z.string(),
  status: PromoCodeRedemptionValidationStatusEnum,
  isValid: z.boolean(),
  amountOff: z.string(),
});

export const postCouponValidatePromoCodeRedemption = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons/promotion-codes/validate-redemption",
  "post",
  {
    path: CouponCommonPathSchema,
    body: CouponValidatePromoCodeRedemptionPayloadSchema,
  },
  {
    description: "Validates if a promotion code can be redeemed.",
    tags,
  },
  CouponValidatePromoCodeRedemptionPostResponseSchema
);

/*************************************************************************************************/

/* /api/{apiVersion}/users/{userId}/coupons/promotion-codes/validate                             */

const CouponValidatePromoCodePayloadSchema = z.object({
  promotionCode: z.string(),
});
const CouponValidatePromoCodePostResponseSchema = z.object({
  promotionCode: z.string(),
  isValid: z.boolean(),
});

export const postCouponValidatePromoCode = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons/promotion-codes/validate",
  "post",
  {
    path: CouponCommonPathSchema,
    body: CouponValidatePromoCodePayloadSchema,
  },
  {
    description: "Validates if a promotion code already exists.",
    tags,
  },
  CouponValidatePromoCodePostResponseSchema
);

/*************************************************************************************************/

/* /api/{apiVersion}/users/{userId}/coupons/                                                     */

const CouponPostPayloadSchema = CouponSchema.pick({
  title: true,
  duration: true,
  unit: true,
  promotionCode: true,
  internal: true,
  value: true,
  maxRedemptions: true,
}).extend({
  expiration: ZodDateAsString.optional(),
});

const CouponPostResponseSchema = CouponSchema;

export const postCoupon = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons",
  "post",
  {
    path: CouponCommonPathSchema,
    body: CouponPostPayloadSchema,
  },
  {
    description: "Creates a coupon.",
    tags,
  },
  CouponPostResponseSchema
);

const CouponGetAllResponseSchema = z.array(CouponSchema);

export const getCoupons = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons",
  "get",
  {
    path: CouponCommonPathSchema,
  },
  {
    description: "Get all coupons for a user.",
    tags,
  },
  CouponGetAllResponseSchema
);

/*************************************************************************************************/

/* /api/{apiVersion}/users/{userId}/coupons/{couponId}                                           */

const CouponPutPathSchema = CouponCommonPathSchema.extend({
  couponId: z.string(),
});

const CouponPutPayloadSchema = CouponSchema.pick({
  title: true,
});

const CouponPutResponseSchema = CouponSchema;

export const putCoupon = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons/{couponId}",
  "put",
  {
    path: CouponPutPathSchema,
    body: CouponPutPayloadSchema,
  },
  {
    description: "Edits a coupon.",
    tags,
  },
  CouponPutResponseSchema
);

const CouponDeletePathSchema = CouponCommonPathSchema.extend({
  couponId: z.string(),
});

const CouponDeleteResponseSchema = CouponSchema;

export const deleteCoupon = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/coupons/{couponId}",
  "delete",
  {
    path: CouponDeletePathSchema,
  },
  {
    description: "Deletes a coupon.",
    tags,
  },
  CouponDeleteResponseSchema
);

/*************************************************************************************************/
