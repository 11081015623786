import z from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { OpenHoursSchema } from "./scheduler";

export const AvailabilityBlockSchema = BaseSchema.extend({
  title: z.string(),
  timezone: z.string(),
  openHours: z.array(OpenHoursSchema).optional(),

  readCalendars: z.array(z.object({ id: z.string() }).passthrough()).optional(),
  writeCalendar: z
    .object({ id: z.string(), integration: z.any().optional() })
    .passthrough(),

  // @TODO: Remove the optional flag once the database is migrated => WEB-7758
  accountId: z.string().optional(),
  // @TODO: Some fields are stored as numbers or do not exists. In order to
  //        use the schema validation, we needed to add the fields below.
  //        We should update those fields using a migration script
  createdAt: z.date().or(z.number()).optional(),
  updatedAt: z.date().or(z.number()).optional(),
});

export type AvailabilityBlockType = z.infer<typeof AvailabilityBlockSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "availabilityBlocks",
  deleteMode: "hard",
};
