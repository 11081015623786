import { z, ZodObject, ZodRawShape, ZodSchema, ZodType } from "zod";

export type VERB = "get" | "post" | "put" | "patch" | "delete";

type SwaggerInfo<INPUT extends Record<string, ZodType>> = {
  description: string;
  tags: string[];
  errors?: [
    {
      code: number;
      description: string;
    },
  ];
  // TODO Fix type
  inputDescriptions?: {
    [X in keyof INPUT]?: { [Z in keyof z.infer<INPUT[X]>]?: string };
  };
};

type EndpointInput<P extends ZodRawShape, Q extends ZodRawShape, B> = {
  path?: ZodObject<P>;
  query?: ZodObject<Q>;
  body?: ZodSchema<B>;
};

export type SchemaDefinition = ReturnType<typeof getSchemaDefinition>;

export const getSchemaDefinition = <
  P extends ZodRawShape,
  Q extends ZodRawShape,
  B,
  INPUT extends EndpointInput<P, Q, B>,
  O,
>(
  endpointPath: string,
  type: VERB,
  input: INPUT,
  swagger: SwaggerInfo<INPUT>,
  output: ZodSchema<O>,
  userEndpoint?: boolean
) => {
  return {
    input: z.object(input),
    output,
    axios: {
      path: endpointPath,
      type,
    },
    swagger,
    userEndpoint,
  };
};

export const IMPORT_APPOINTMENT_KEYS = {
  clientFirstName: "Invitee First Name",
  clientLastName: "Invitee Last Name",
  clientEmail: "Invitee Email",
  clientTimezone: "Invitee Time Zone",
  phoneNumber: "Text Reminder Number",
  title: "Event Type Name",
  evenStartDate: "Start Date & Time",
  eventEndDate: "End Date & Time",
  location: "Location",
  // createdAt: "Event Created Date & Time",
  canceled: "Canceled",
  markedAsNoShow: "Marked as No-Show",
  memberEmail: "User Email",
  customEventId: "Event UUID",
};
