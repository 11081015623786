import { z } from "zod";

import { getSchemaDefinition } from "../common";

const tags = ["notes"];

const NoteCommonPathSchema = z.object({
  userId: z.string(),
  clientId: z.string(),
  noteId: z.string(),
});
export const deleteNote = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/notes/{noteId}",
  "delete",
  {
    path: NoteCommonPathSchema,
  },
  {
    description: "Soft deletes a note doc setting its status as deleted",
    tags,
  },
  z.object({ id: z.string() })
);

const NoteAutoSavePayloadSchema = z.object({
  title: z.string().optional(),
  richContent: z.string().optional(),
});
export const patchNoteAutoSave = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/notes/{noteId}/autosave",
  "patch",
  {
    path: NoteCommonPathSchema,
    body: NoteAutoSavePayloadSchema,
  },
  {
    description: "Auto saves a note content and title",
    tags,
  },
  z.object({
    status: z.literal("ok"),
  })
);

export const updateNoteLock = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/notes/{noteId}/update-lock",
  "post",
  {
    path: NoteCommonPathSchema,
    body: z.object({ locked: z.boolean() }),
  },
  {
    description: "Updates the note lock",
    tags,
  },
  z.object({
    status: z.literal("ok"),
  })
);

export const getNote = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/clients/{clientId}/notes/{noteId}",
  "get",
  {
    path: z.object({
      orgId: z.string(),
      clientId: z.string(),
      noteId: z.string(),
    }),
  },
  {
    description: "Get note",
    tags,
  },
  z.object({
    data: z
      .object({
        id: z.string(),
      })
      .catchall(z.any()),
  })
);
