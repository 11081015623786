import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getAccountContext,
  updateOrganizationAccount,
  WorkspaceType,
} from "api-services/definitions/accounts";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import { useApi, useApiGet } from "api-services/endpoints";
import axios from "axios";
import { GoogleAuthProvider } from "firebase/auth";
import { DateTime } from "luxon";
import moment, { unix } from "moment";
import { useRouter } from "next/router";
import nookies from "nookies";
import queryString from "query-string";
import Stripe from "stripe";

import useAccount from "@hooks/use-account";
import { useSync } from "@hooks/use-appointments";
import useOrganizationAccounts, {
  UseOrganizationAccountsType,
} from "@hooks/use-organization-accounts";
import useOrganizationsForAccount from "@hooks/use-organizations-for-account";
import usePersistentState from "@hooks/use-persistent-state";
import { usePrefetchRoutes } from "@hooks/use-prefetch-routes";
import useStripeInfo from "@hooks/use-stripe-info";
import useUser from "@hooks/use-user";
import analytics from "@lib/analytics";
import { AccountType } from "@lib/data/schemas/account";
import { OrganizationType } from "@lib/data/schemas/organization";
import { UserType } from "@lib/data/schemas/user";
import { datadogRum } from "@lib/datadog-rum";
import { compatAuth as auth } from "@lib/firebase-config";
import { translateToSubscriptionPlan } from "@lib/utils/subscription-plans";

type DeprecatedAuthContextType = {
  /**
   * @deprecated use `oid` instead. If you're looking for the account id, use `aid` instead
   */
  uid?: string;
  /**
   * @deprecated use `oid` instead. If you're looking for the account id, use `aid` instead
   */
  userId?: string;
  /**
   * @deprecated use `organization` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firestoreUser?: any;
  /**
   * @deprecated use `organization?.slug` instead.
   */
  slug?: string;
  /**
   * @deprecated use `useStripeInfo(oid)` hook instead.
   */
  subscription?: Stripe.Subscription | undefined;
  /**
   * @deprecated use `useStripeInfo(oid)` hook instead.
   */
  upcomingInvoice?: Stripe.Invoice | undefined;
  /**
   * @deprecated use `useStripeInfo(oid)` hook instead.
   */
  customer?: Stripe.Customer | undefined;
};

type CurrentWorkspace = {
  id: string;
  role: "client" | "coach" | "";
};

type AuthContextType = {
  /**
   * The organization id of the current user
   */
  oid: string | undefined;
  /**
   * The organization, this user is a member of. If this user is a member of multiple organizations, this will be the first organization.
   */
  organization: OrganizationType | undefined;
  account: AccountType | undefined;
  aid: string | undefined;
  ownerId: string | undefined;
  isOwner: boolean | undefined;
  isCoach: boolean | undefined;
  onboarding: UserType["onboarding"] | undefined;
  signin(email: string, pass: string): Promise<{ uid: string } | null>;
  signup(email: string, pass: string): Promise<{ uid: string } | null>;
  signout(): Promise<boolean>;
  signoutInternal(): Promise<void>;
  signInWithCustomToken(token: string): Promise<void>;
  signInWithCredential(token: string): Promise<void>;
  sendPasswordResetEmail(email: string): Promise<boolean>;
  confirmPasswordReset(pass: string, code: string): Promise<boolean>;
  setCurrentWorkspace: (workspace: CurrentWorkspace) => void;
  onWorkspaceSwitch?: () => void;
  loading: boolean;
  loadingUid: boolean;
  hasPassword: boolean | undefined;
  hasVerifiedEmail: boolean | undefined;
  authEmail: string | undefined;
  showSubscriptionWall: boolean;
  showPaymentWall: boolean;
  hasTrialEnded: boolean;
  isAccountPaused: boolean;
  subscriptionPlan: ExtendedStripeSubscriptionPlan | null;
  hasAccountPaymentMethod: boolean;
  currentWorkspace?: CurrentWorkspace;
  contexts?: WorkspaceType[];
  organizationAccounts: ReturnType<UseOrganizationAccountsType>;
} & DeprecatedAuthContextType;

const AuthContext = createContext<AuthContextType>({
  oid: undefined,
  organization: undefined,
  account: undefined,
  aid: undefined,
  ownerId: undefined,
  isOwner: undefined,
  isCoach: undefined,
  onboarding: undefined,
  signin: async () => null,
  signup: async () => null,
  signInWithCustomToken: async () => {
    return;
  },
  signInWithCredential: async () => {
    return;
  },
  signout: async () => {
    return false;
  },
  sendPasswordResetEmail: async () => false,
  confirmPasswordReset: async () => false,
  loading: false,
  loadingUid: false,
  hasPassword: undefined,
  hasVerifiedEmail: undefined,
  authEmail: undefined,
  showPaymentWall: false,
  showSubscriptionWall: false,
  hasTrialEnded: false,
  isAccountPaused: false,
  subscriptionPlan: null,
  hasAccountPaymentMethod: false,
  setCurrentWorkspace: async () => false,
  currentWorkspace: undefined,
  signoutInternal: async () => undefined,
  organizationAccounts: {} as ReturnType<UseOrganizationAccountsType>,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const ap = useProvideAuth();

  useEffect(() => {
    datadogRum.setUser({
      id: ap.account?.id,
      email: ap.account?.email,
      oid: ap.organization?.id,
    });
  }, [ap.account, ap.organization]);

  return <AuthContext.Provider value={ap}>{children}</AuthContext.Provider>;
};

const refreshGmailSync = async (oid: string | undefined) => {
  if (!oid) return;

  axios.get(`/api/v1/gmail/${oid}/sync?refresh=true`);
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

function useProvideAuth(): AuthContextType {
  const [authId, setAuthId] = useState<string>();
  const [isLoadingAuthUid, setIsLoadingAuthUid] = useState(true);
  const [methods, setMethods] = useState<undefined | string[]>();
  const router = useRouter();
  const {
    persistentValue: currentWorkspace,
    persistentSetValue: setCurrentWorkspace,
  } = usePersistentState<CurrentWorkspace>("currentWorkspace", {
    id: "",
    role: "",
  });

  const handleAuthUid = useCallback(
    async (uid?: string, email?: string) => {
      if (!uid) setCurrentWorkspace({ id: "", role: "" });
      setAuthId(uid);

      if (email) {
        const methods = await auth.fetchSignInMethodsForEmail(email);
        setMethods(methods);
      } else {
        setMethods(undefined);
      }

      setIsLoadingAuthUid(false);
    },
    [setCurrentWorkspace]
  );

  const { account, loading: loadingAccount } = useAccount(authId);
  const aid = account?.id;

  const { organizations, loading: loadingOrganization } =
    useOrganizationsForAccount(aid);

  const isCoach = currentWorkspace?.role === "coach";

  const { data: contexts } = useApiGet(
    aid ? getAccountContext : undefined,
    aid
      ? {
          accountId: aid,
          apiVersion: "v1",
        }
      : undefined,
    {},
    { dedupingInterval: 600000 }
  );

  const { apiCall: updateMember } = useApi(updateOrganizationAccount);

  const oid = useMemo(() => {
    if (aid && currentWorkspace?.id && currentWorkspace.role === "coach")
      return currentWorkspace.id;

    // if we're still loading the account, we don't know the oid yet
    if (loadingAccount) return undefined;

    // if account doesn't exist, the aid becomes the oid
    if (!account) return aid;

    if (loadingOrganization) return undefined;

    // if there is no organization, its a client account, so the oid is the aid
    return organizations?.[0]?.id || aid;
  }, [
    currentWorkspace,
    loadingAccount,
    account,
    aid,
    loadingOrganization,
    organizations,
  ]);

  const organization =
    organizations?.find((org) => org.id === oid) || organizations?.[0];

  const { user: firestoreUser, loading: loadingUser } = useUser(oid);

  const coachOrgId = useMemo(() => {
    if (currentWorkspace?.role === "coach") return currentWorkspace.id;
    if (firestoreUser?.isCoach) return oid;
    return undefined;
  }, [currentWorkspace, firestoreUser?.isCoach, oid]);

  useSync(coachOrgId);
  const organizationAccounts = useOrganizationAccounts(coachOrgId);
  const { subscription, upcomingInvoice, customer } = useStripeInfo(coachOrgId);

  const userSubscription = firestoreUser?.subscription;
  const userSubscriptionStatus = userSubscription?.status;
  const isAccountPaused = firestoreUser?.accountStatus === "paused";
  const showSubscriptionWall =
    isAccountPaused && userSubscriptionStatus === "canceled";
  const showPaymentWall =
    (userSubscriptionStatus === "invalid-payment" || isAccountPaused) &&
    !showSubscriptionWall;

  const subscriptionStatus = subscription?.status || "no_subscription";
  const plan = subscription?.items?.data[0].plan;
  const subscriptionPlan = plan ? translateToSubscriptionPlan(plan) : null;
  const trialStart =
    subscription?.trial_start && moment(subscription.trial_start * 1000);
  const paymentFailedDate = moment(
    userSubscription?.paymentFailedDate?.seconds * 1000
  );
  const hasAccountPaymentMethod =
    !!subscription?.default_payment_method ||
    userSubscriptionStatus === "invalid-payment";
  const hasTrialEnded =
    (!isAccountPaused &&
      !hasAccountPaymentMethod &&
      trialStart &&
      trialStart.isSame(paymentFailedDate, "day")) ||
    showSubscriptionWall ||
    false;

  const signin = (email: string, pass: string) => {
    return auth
      .signInWithEmailAndPassword(email, pass)
      .then(async (response) => {
        await handleAuthUid(response?.user?.uid, email);
        analytics.track("user_authenticated", { login_method: "email" });
        return response.user;
      });
  };

  const signup = (email: string, pass: string) => {
    return auth
      .createUserWithEmailAndPassword(email, pass)
      .then(async (response) => {
        await handleAuthUid(response?.user?.uid, email);
        analytics.track("user_authenticated");
        analytics.track("user_created");
        return response.user;
      });
  };

  usePrefetchRoutes(["/logout"]);

  const signout = async () => {
    return router.push("/logout");
  };

  const signoutInternal = async () => {
    nookies.destroy(undefined, "firebase_token");
    return auth.signOut().then(async () => {
      await handleAuthUid(undefined, undefined);
    });
  };

  const sendPasswordResetEmail = (email: string) => {
    return auth.sendPasswordResetEmail(email).then(() => {
      return true;
    });
  };

  const confirmPasswordReset = (pass: string, code: string) => {
    const resetCode =
      code || (queryString.parse(window.location.search)["oobCode"] as string);
    return auth.confirmPasswordReset(resetCode, pass).then(() => {
      return true;
    });
  };

  const signInWithCustomToken = (token: string) => {
    return auth.signInWithCustomToken(token).then(async (response) => {
      await handleAuthUid(
        response?.user?.uid,
        response?.user?.email || undefined
      );
    });
  };

  const signInWithCredential = useCallback(
    (token: string) => {
      return auth
        .signInWithCredential(GoogleAuthProvider.credential(token))
        .then(async (response) => {
          await handleAuthUid(
            response?.user?.uid,
            response?.user?.email || undefined
          );
          analytics.track("user_authenticated", { login_method: "google" });
        });
    },
    [handleAuthUid]
  );

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        nookies.set(undefined, "firebase_token", token, { path: "/" });
        await handleAuthUid(user.uid, user.email || undefined);
      } else {
        await handleAuthUid(undefined, undefined);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!firestoreUser || !firestoreUser.exists || !aid) return;

    const isCoach = firestoreUser.isCoach || false;
    const admin = firestoreUser.admin || false;
    const seconds = firestoreUser?.createdAt?.seconds;
    const email = firestoreUser?.email;
    const onboardSec = firestoreUser?.coachOnboardedAt?.seconds;
    const formattedCreatedAt = seconds && unix(seconds).toISOString();
    const onboardedAt = onboardSec && unix(onboardSec).toISOString();

    analytics.identify(aid, {
      isCoach,
      admin,
      subscriptionStatus,
      createdAt: formattedCreatedAt,
      coachOnboardedAt: onboardedAt,
      email,
      organizationId: oid,
      organizationRole: organization?.ownerId === aid ? "owner" : "member",
      ...(subscriptionPlan && {
        subscriptionFrequency: subscriptionPlan.frequency,
        subscriptionTier: subscriptionPlan.tier,
      }),
    });
  }, [firestoreUser, subscriptionStatus]);

  useEffect(() => {
    if (!account) return;

    if (!account.timeZone) {
      updateMember(
        {
          accountId: aid!,
          userId: oid!,
          apiVersion: "v1",
        },
        {
          timeZone: DateTime.local().zoneName,
        },
        {}
      );
    }
  }, [account]);

  const orgHasEmailIntegration =
    !!organizationAccounts.integrations?.emails.length;

  useEffect(() => {
    if (!oid) return;

    if (orgHasEmailIntegration) refreshGmailSync(oid);
  }, [oid, orgHasEmailIntegration]);

  /**
   * This 'user' now actually represents the organization. If looking up the organization
   * by an account id fails, we can use this 'user' as a fallback. It will contain
   * all the required data that would otherwise be split into account and organization.
   */
  const user = firestoreUser as UserType;

  const hasVerifiedEmail = auth.currentUser?.emailVerified;
  const authEmail = auth.currentUser?.email;

  const onWorkspaceSwitch =
    (contexts?.workspaces?.length || 0) > 1
      ? () => {
          setCurrentWorkspace({ id: "", role: "" });
          router.push("/login");
        }
      : undefined;

  return {
    oid,
    organization: organization || user,
    aid,
    account:
      account ||
      (user && {
        ...user,
        // First name and last name are optional in the user object but aren't in accounts.
        firstName: user.firstName || "",
        lastName: user.lastName || "",
      }),
    ownerId: organization?.ownerId,
    isOwner: oid ? organization?.ownerId === aid : undefined,
    isCoach,
    userId: oid,
    uid: oid,
    firestoreUser: user,
    onboarding: account?.onboarding ?? user?.onboarding,
    slug: firestoreUser?.slug,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    loadingUid: isLoadingAuthUid,
    signInWithCustomToken,
    signInWithCredential,
    loading:
      isLoadingAuthUid || loadingAccount || loadingOrganization || loadingUser,
    hasPassword: methods?.includes("password"),
    hasVerifiedEmail,
    authEmail: authEmail || undefined,
    subscription,
    upcomingInvoice,
    customer,
    isAccountPaused,
    hasAccountPaymentMethod,
    subscriptionPlan,
    showSubscriptionWall,
    showPaymentWall,
    hasTrialEnded,
    setCurrentWorkspace,
    currentWorkspace,
    contexts: contexts?.workspaces,
    onWorkspaceSwitch,
    signoutInternal,
    organizationAccounts,
  };
}
