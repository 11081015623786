import { useEffect } from "react";
import { useRouter } from "next/router";

export const usePrefetchRoutes = (routes: string[] = []) => {
  const router = useRouter();

  useEffect(() => {
    if (routes === null) return;
    (typeof routes === "string" ? [routes] : routes).forEach((r) =>
      router.prefetch(r)
    );
  }, [router, routes, router.prefetch]);

  return null;
};
