import { useContext } from "react";

import {
  SnackbarContext,
  SnackbarContextProps,
} from "@components/SnackbarProvider";

const useSnackbar = () => useContext<SnackbarContextProps>(SnackbarContext);

export default useSnackbar;
