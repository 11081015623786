import React, { createContext, useCallback, useRef, useState } from "react";
import { noop } from "lodash";

import usePersistentState from "@hooks/use-persistent-state";

import FeedbackNotification from "./FeedbackNotification";

export interface SnackbarContextProps {
  showActionsMessage: (
    title: string,
    description: string,
    actions: any[]
  ) => void;
  showMessage: (
    title: string,
    description?: string,
    actionTitle?: string,
    handleAction?: () => void
  ) => void;
  showWarning: (
    title: string,
    description?: string,
    actionTitle?: string,
    handleAction?: () => void,
    storageKey?: string
  ) => void;
  dismiss: () => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  showActionsMessage: noop,
  showMessage: noop,
  showWarning: noop,
  dismiss: noop,
});

const SnackbarProvider = (props: any) => {
  const timer = useRef<any>();

  const [isVisible, setIsVisible] = useState(false);
  const [
    {
      actionTitle,
      description,
      handleAction,
      title,
      actions,
      storageKey,
      warning,
    },
    setSnackbarState,
  ] = useState<{
    actionTitle?: string;
    description?: string;
    handleAction?: () => void;
    title?: string;
    actions?: any[];
    storageKey?: string;
    warning?: boolean;
  }>({
    actionTitle: undefined,
    description: undefined,
    handleAction: undefined,
    title: undefined,
    actions: [],
    storageKey: undefined,
    warning: false,
  });
  const { children, SnackbarComponent = FeedbackNotification } = props;

  const { persistentSetValue } = usePersistentState(
    storageKey,
    { visited: false },
    true
  );

  const handleClose = useCallback(() => {
    if (storageKey) persistentSetValue({ visited: true });
    setIsVisible(false);
  }, [storageKey, persistentSetValue]);

  const handleActionClick = useCallback(() => {
    handleAction?.();
    handleClose();
  }, [handleAction, handleClose]);

  const handlerTimer = useCallback(
    (timing = 5000) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        handleClose();
        setTimeout(() => setSnackbarState({}), 300);
      }, timing);
      return () => clearTimeout(timer.current);
    },
    [handleClose]
  );

  const showMessage = useCallback(
    (title, description, actionTitle, handleAction) => {
      setSnackbarState({
        actionTitle,
        description,
        handleAction,
        title,
      });
      setIsVisible(true);
      handlerTimer();
    },
    [handlerTimer]
  );

  const showActionsMessage = useCallback((title, description, actions) => {
    setSnackbarState({ title, description, actions });
    setIsVisible(true);
    handlerTimer();
  }, []);

  const showWarning = useCallback(
    (title, description, actionTitle, handleAction, storageKey) => {
      setSnackbarState({
        title,
        description,
        actionTitle,
        handleAction,
        warning: true,
        storageKey,
      });
      setIsVisible(true);
    },
    [handlerTimer]
  );

  const dismiss = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onEnter = () => clearTimeout(timer.current);
  const onLeave = () => handlerTimer(500);

  return (
    <>
      <SnackbarContext.Provider
        value={{ showMessage, showActionsMessage, showWarning, dismiss }}
      >
        {children}
      </SnackbarContext.Provider>
      <SnackbarComponent
        {...{ isVisible }}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        props={{
          action: handleActionClick,
          actionTitle,
          description,
          dismiss: handleClose,
          title,
          actions,
          warning,
        }}
      />
    </>
  );
};

export default SnackbarProvider;
