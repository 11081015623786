import React, { useCallback, useState } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

import { Button } from "@components/Button";
import CaretIcon from "@components/Icons/CaretIcon";

import AlertIcon from "./Icons/AlertIcon";
import CloseNotificationIcon from "./Icons/CloseNotificationIcon";

const NotificationActions = ({ actions }) => {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className="relative">
      <Button className="p-1.5" small dark onClick={onClick}>
        <span className="font-normal mr-1">Actions</span>
        <CaretIcon className="inline mx-2" />
      </Button>
      <Transition
        show={open}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        className="absolute z-50 transform rounded-lg bottom-full right-0 p-4 overflow-hidden bg-grey-150 w-60 flex flex-col mb-2"
      >
        {actions.map((action, i) => (
          <button
            className="focus:outline-none pointer text-white hover:text-grey-500 text-left truncate my-1"
            key={i}
            onClick={action.onClick}
          >
            {action.title}
          </button>
        ))}
      </Transition>
    </div>
  );
};

const FeedbackNotification = ({
  props,
  isVisible,
  onMouseEnter,
  onMouseLeave,
}) => {
  const actionPressed = () => {
    props.action();
    props.dismiss();
  };

  const dismissPressed = () => {
    props.dismiss();
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="fixed z-[51] inset-4 sm:inset-auto sm:right-8 sm:bottom-8 flex items-end justify-center pointer-events-none sm:items-start sm:justify-end"
    >
      <Transition
        show={isVisible}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={classNames(
          "max-w-md w-full rounded-lg pointer-events-auto ring-1 ring-black/5 cursor-pointer",
          props.warning ? "bg-peach-950" : "bg-black-ink  shadow-lg"
        )}
      >
        <div className="pl-6 pr-2 py-4">
          <div className="flex items-center">
            {props.warning && (
              <AlertIcon className="mr-4 text-peach-600 w-6 h-6" />
            )}
            <div
              className={classNames(
                "flex-1 min-w-xxxxs",
                !props.actionTitle && "pr-4"
              )}
            >
              <p
                className={classNames(
                  props.warning ? "text-black-ink  font-medium" : "text-white"
                )}
              >
                {props.title}
              </p>
              <p className="text-xs text-grey-500">{props.description}</p>
            </div>
            {props.actionTitle ? (
              <div className="shrink-0 flex justify-center">
                <button
                  onClick={actionPressed}
                  className={classNames(
                    "py-2 px-4 text-sm font-medium hover:text-grey-500 focus:outline-none",
                    props.warning ? "text-black-ink" : "text-white"
                  )}
                >
                  {props.actionTitle}
                </button>
              </div>
            ) : null}
            {props.actions && <NotificationActions actions={props.actions} />}
            {!props.actions && (
              <div className="shrink-0 flex border-white/25 border-l">
                <button
                  onClick={dismissPressed}
                  className={classNames(
                    "py-2 px-4 focus:outline-none",
                    props.warning
                      ? "text-grey-300 hover:text-black-ink"
                      : "text-white hover:text-grey-500"
                  )}
                >
                  <span className="sr-only">Close</span>
                  <CloseNotificationIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default FeedbackNotification;
