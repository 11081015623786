import moment from "moment";
import { z } from "zod";

import { LogSchema } from "@lib/data/schemas/log";

import { getSchemaDefinition, IMPORT_APPOINTMENT_KEYS } from "../common";

const AppointmentCommonPathSchema = z.object({
  userId: z.string(),
  appointmentId: z.string(),
});

const tags = ["appointments"];

const IntegrationSchema = z.object({
  provider: z.string(),
  memberId: z.string(),
  integrationId: z.string().optional(),
  email: z.string(),
});

export const postFollowUps = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/follow-ups",
  "post",
  {
    path: AppointmentCommonPathSchema,
  },
  {
    description:
      "Sends folow ups emails to coaches and clients after the appointment ends",
    tags,
  },
  LogSchema.optional()
);

const CancelAppointmentPathSchema = z.object({
  userId: z.string(),
});

const CancelAppointmentPayloadSchema = z.object({
  appointmentId: z.string(),
  reschedule: z.boolean().optional(),
  sendEmailNotifications: z.boolean().optional(),
  origin: z.enum(["client", "coach"]).optional(),
  updateOutcome: z.boolean().optional(),
  outcomeId: z.string().optional(),
  reason: z.string().optional(),
});

const CancelAppointmentResponseSchema = z.object({
  appointmentId: z.string(),
});

export const postCancelAppointment = getSchemaDefinition(
  "/api/users/{userId}/appointments/cancel",
  "post",
  {
    path: CancelAppointmentPathSchema,
    body: CancelAppointmentPayloadSchema,
  },
  {
    description: "Cancel an appointment",
    tags: ["appointments"],
  },
  CancelAppointmentResponseSchema
);

const UpdateAppointmentPayloadSchema = z.object({
  title: z.string(),
  start: z.string(),
  timeZone: z.string().optional(),
  duration: z.number(),
  location: z.string().optional(),
  locationType: z.string(),
  locationMemberId: z.string().optional(),
  writeCalendarId: z.string(),
  googleAccountId: z.string().optional(),
  eventData: z.unknown().optional(),
  slug: z.string().optional(),
  clientControlAction: z.string().optional(),
  hasMinimumNotice: z.boolean().optional(),
  minimumNoticeDuration: z.number().optional(),
  noticeReasonRequired: z.boolean().optional(),
  organizerId: z.string().optional(),
  integration: IntegrationSchema.optional(),
  isReassigning: z.boolean().optional(),
});

const UpdateAppointmentResponseSchema = z.object({
  appointmentId: z.string(),
});

export const postUpdateAppointment = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/edit",
  "post",
  {
    path: AppointmentCommonPathSchema,
    body: UpdateAppointmentPayloadSchema,
  },
  {
    description: "Cancel an appointment",
    tags,
  },
  UpdateAppointmentResponseSchema
);

export const getAppointmentPotentialConflicts = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/potential-conflicts",
  "get",
  {
    path: z.object({ userId: z.string() }),
    query: z.object({
      writeCalendarId: z.string(),
      start: z.string(),
      duration: z.string(),
      eventId: z.string().optional(),
      integrationId: z.string().optional(),
      provider: z.string(),
      accountId: z.string().optional(),
    }),
  },
  {
    description: "Get potential conflicts for an appointment",
    tags,
  },
  z.array(
    z.object({
      start: z.string(),
      end: z.string(),
      title: z.string(),
      type: z.enum(["all_day", "event"]),
    })
  )
);

export const postAppointmentOutcome = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/outcome",
  "post",
  {
    path: z.object({ userId: z.string(), appointmentId: z.string() }),
    body: z.object({
      createdBy: z.string(),
      note: z.string().optional(),
      origin: z.string().optional(),
      outcomeId: z.string(),
    }),
  },
  {
    description: "Adds an outcome to an appointment",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const putAppointmentPrimaryContact = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/update-primary-contact",
  "post",
  {
    path: z.object({ userId: z.string(), appointmentId: z.string() }),
    body: z.object({
      contactId: z.string(),
      memberId: z.string(),
    }),
  },
  {
    description: "Update the primary contact of an appointment",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const deleteAppointmentOutcome = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/outcome",
  "delete",
  {
    path: z.object({ userId: z.string(), appointmentId: z.string() }),
    body: z.object({ memberId: z.string() }),
  },
  {
    description: "Deletes an outcome from an appointment",
    tags,
  },
  z.object({ ok: z.boolean() })
);

/**
 * Link and unlink an appointment to a package instance
 * */
export const postLinkAppointmentToPackage = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/{appointmentId}/link-package",
  "post",
  {
    path: z.object({ userId: z.string(), appointmentId: z.string() }),
    body: z.object({
      packageInstanceId: z.string(),
      action: z.enum(["add", "remove"]),
      packageInstanceOwnerId: z.string().optional(),
      cycle: z.number().optional(),
      availabilityId: z.string().optional(),
    }),
  },
  {
    description: "Link and unlink an appointment to a package instance",
    tags,
  },
  z.object({ status: z.string() })
);

const dateString = z.string().refine(
  (date: string) => moment.utc(date).isValid(),
  (val) => ({
    message: `Invalid timezone: ${val}`,
  })
);

const emailString = z.string().refine(
  (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  (val) => ({
    message: `Invalid email: ${val}`,
  })
);

const excelData = z.object({
  [IMPORT_APPOINTMENT_KEYS.title]: z.string(),
  [IMPORT_APPOINTMENT_KEYS.clientFirstName]: z.string(),
  [IMPORT_APPOINTMENT_KEYS.clientLastName]: z.string(),
  [IMPORT_APPOINTMENT_KEYS.clientEmail]: emailString,
  [IMPORT_APPOINTMENT_KEYS.clientTimezone]: z.string().optional(),
  [IMPORT_APPOINTMENT_KEYS.phoneNumber]: z.string().optional(),
  [IMPORT_APPOINTMENT_KEYS.evenStartDate]: dateString,
  [IMPORT_APPOINTMENT_KEYS.eventEndDate]: dateString,
  [IMPORT_APPOINTMENT_KEYS.location]: z.string(),
  [IMPORT_APPOINTMENT_KEYS.canceled]: z.enum(["FALSE", "TRUE"]),
  [IMPORT_APPOINTMENT_KEYS.markedAsNoShow]: z.enum(["Yes", "No"]),
  [IMPORT_APPOINTMENT_KEYS.memberEmail]: emailString,
  [IMPORT_APPOINTMENT_KEYS.customEventId]: z.string(),
});

const ExcelDataSchema = z.object({ data: excelData });

export const importAppointments = getSchemaDefinition(
  "/api/v1/users/{userId}/appointments/import",
  "post",
  {
    path: z.object({ userId: z.string() }),
    body: ExcelDataSchema,
  },
  {
    description: "Import appointments from a CSV data row",
    tags,
  },
  z.object({ status: z.string(), errors: z.any().optional() })
);

export const getAppointments = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/appointments",
  "get",
  {
    path: z.object({
      orgId: z.string(),
    }),
    query: z.object({
      isPast: z.string().optional(),
      onlyEvents: z.string().optional(),
      startDate: z.string().optional(),
      clientId: z.string().optional(),
      cursorId: z.string().optional(),
      limit: z.string().optional(),
      memberId: z.string().optional(),
      showHidden: z.string().optional(),
    }),
  },
  {
    description: "Get all appointments",
    tags,
  },
  z.object({
    data: z.array(
      z
        .object({
          id: z.string(),
          contacts: z
            .array(z.object({ id: z.string() }).catchall(z.any()))
            .optional(),
          group: z.object({ id: z.string() }).catchall(z.any()).optional(),
          organizer: z.object({ id: z.string() }).catchall(z.any()).optional(),
        })
        .catchall(z.any())
    ),
    cursorId: z.string().optional(),
  })
);

export const getAppointment = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/appointments/{appointmentId}",
  "get",
  {
    path: z.object({
      orgId: z.string(),
      appointmentId: z.string(),
    }),
  },
  {
    description: "Get appointment",
    tags,
  },
  z
    .object({
      id: z.string(),
      contacts: z
        .array(z.object({ id: z.string() }).catchall(z.any()))
        .optional(),
      group: z.object({ id: z.string() }).catchall(z.any()).optional(),
    })
    .catchall(z.any())
);
