import { useCollection } from "swr-firebase";

import { OrganizationType } from "@lib/data/schemas/organization";

type UseOrganizationsForAccountType = (aid?: string) => {
  organizations?: OrganizationType[];
  loading: boolean;
};

const useOrganizationsForAccount: UseOrganizationsForAccountType = (
  aid?: string
) => {
  const { data, loading } = useCollection<OrganizationType>(
    aid ? `users` : null,
    {
      where: ["memberAccountIds", "array-contains", aid],
      listen: true,
    }
  );

  return { organizations: data || undefined, loading: aid ? loading : false };
};

export default useOrganizationsForAccount;
