import { z } from "zod";

import {
  PackageFormDataSchema,
  PackageSchema,
} from "@lib/data/schemas/packages";

import { getSchemaDefinition } from "../common";

const tags = ["packages"];

export const deletePackage = getSchemaDefinition(
  "/api/v1/users/{userId}/packages/{packageId}",
  "delete",
  {
    path: z.object({ userId: z.string(), packageId: z.string() }),
  },
  {
    description: "Delete a package.",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

export const packageUpdatePatch = getSchemaDefinition(
  "/api/v1/users/{userId}/packages/{packageId}",
  "patch",
  {
    path: z.object({ userId: z.string(), packageId: z.string() }),
    body: PackageSchema.pick({ secret: true, publicOrder: true }),
  },
  {
    description: "Update partially a package.",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

export const postCreatePackage = getSchemaDefinition(
  "/api/v1/users/{userId}/packages",
  "post",
  {
    path: z.object({ userId: z.string() }),
    body: PackageFormDataSchema,
  },
  {
    description: "Create a package.",
    tags,
  },
  PackageSchema
);

// @TODO: Those changes are necessary because our database is not consistent
//        with the schema. We need to fix it and remove those changes.
const GetPackagesResponseSchema = PackageSchema.extend({
  createdAt: z.any().optional(),
  updatedAt: z.any().optional(),
  smartActionTemplates: z.any().array().optional(),
  paymentOptions: z.any().array().optional(),
}).partial();

export const getPackages = getSchemaDefinition(
  "/api/v1/users/{userId}/packages",
  "get",
  {
    path: z.object({ userId: z.string() }),
  },
  {
    description: "Get all packages.",
    tags,
  },
  GetPackagesResponseSchema.array()
);

export const packageUpdatePut = getSchemaDefinition(
  "/api/v1/users/{userId}/packages/{packageId}",
  "put",
  {
    path: z.object({ userId: z.string(), packageId: z.string() }),
    body: PackageFormDataSchema,
  },
  {
    description: "Update a package entirely",
    tags,
  },
  PackageSchema
);

export const calculateCycleAmounts = getSchemaDefinition(
  "/api/v1/users/{userId}/packages/{packageId}/calculate-cycle-amounts",
  "get",
  {
    path: z.object({
      userId: z.string(),
      packageId: z.string(),
    }),
    query: z.object({
      startDate: z.string(),
    }),
  },
  {
    description:
      "Calculates the amount of time or sessions that can be added to a package based on start date",
    tags,
  },
  z.object({
    maxMinutes: z.number().nullable(),
    maxSessions: z.number().nullable(),
    cycleCount: z.number(),
    packageData: PackageSchema.optional(),
  })
);
