import { hasFeature as HF } from "api-services/definitions/authorization";
import { useApiGet } from "api-services/endpoints";

import { FeatureNames } from "@lib/constants/featureNames";

/**
 * @remarks
 * If possible, check access to a feature in `getServerSideProps` with `Authz.hasFeatureAccess` instead of using this hook.
 *
 * As this hook requires a call to an API endpoint, the access value is not instantly available.
 */
export default function useHasFeature(
  orgId: string | undefined,
  feature: FeatureNames | undefined
): [boolean | undefined, boolean] {
  const endpoint = orgId && feature ? HF : undefined;
  const path = orgId ? { orgId: orgId } : undefined;
  const query = feature ? { featureName: feature } : undefined;

  const { data } = useApiGet(endpoint, path, query, {
    dedupingInterval: 360000,
  });

  return [data?.authorized, !data];
}
