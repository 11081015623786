import z from "zod";

import { LibraryItemBaseSchema } from "./shared";

export const FolderSchema = LibraryItemBaseSchema.extend({
  title: z.string(),
});

export type Folder = z.infer<typeof FolderSchema>;

export const FolderPatchSchema = z.object({
  title: z.string().optional(),
  status: z.string().optional(),
});
