import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

export const CouponStatusEnum = z.enum(["active", "inactive", "deleted"]);
export type CouponStatusEnumType = z.infer<typeof CouponStatusEnum>;

export const CouponDurationTypeEnum = z.enum(["once", "repeating", "forever"]);
export type CouponDurationTypeEnumType = z.infer<typeof CouponDurationTypeEnum>;

export const CouponDurationSchema = z
  .object({
    type: CouponDurationTypeEnum,
    period: z.literal("months").optional(),
    quantity: z.number().optional(),
  })
  .refine(
    ({ type, period, quantity }) =>
      type === "once" || type == "forever" ? true : !!period && !!quantity,
    { message: "period and quantity are required if type=repeating" }
  );

export type CouponDurationType = z.infer<typeof CouponDurationSchema>;

export const CouponSchema = BaseSchema.extend({
  title: z.string(),
  value: z.number(),
  unit: z.string(),
  status: CouponStatusEnum,
  duration: CouponDurationSchema,
  stripe: z.object({
    stripe_coupon_id: z.string(),
  }),
  internal: z.boolean(),
  promotionCode: z.string().optional(),
  maxRedemptions: z.number().optional(),
  expiration: z.date().optional(),
});
export type CouponType = z.infer<typeof CouponSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "coupons",
  deleteMode: "soft",
};
