import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

const LogEventEnum = z.enum(["follow-ups", "messaging-email-notification"]);

export const LogSchema = z.object({
  id: z.string().optional(),
  appointmentId: z.string(),
  contactId: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date().optional(),
  event: LogEventEnum,
});

export type LogType = z.infer<typeof LogSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "log",
  deleteMode: "none",
};
