import { useMemo } from "react";
import { Logger } from "pino";

import { getLogger } from "@lib/logger";

type UseLoggerHook = (name?: string) => { logger: Logger };

const useLogger: UseLoggerHook = (name?: string) => {
  const logger = useMemo(() => getLogger(name), [name]);
  return { logger };
};

export default useLogger;
