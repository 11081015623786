import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.98922 6.69633C5.79396 6.89159 5.79396 7.20817 5.98922 7.40343L10.5854 11.9996L5.98922 16.5958C5.79396 16.7911 5.79396 17.1077 5.98922 17.3029L6.69633 18.01C6.89159 18.2053 7.20817 18.2053 7.40343 18.01L11.9996 13.4138L16.5958 18.01C16.7911 18.2053 17.1077 18.2053 17.3029 18.01L18.01 17.3029C18.2053 17.1077 18.2053 16.7911 18.01 16.5958L13.4138 11.9996L18.01 7.40343C18.2053 7.20817 18.2053 6.89159 18.01 6.69633L17.3029 5.98922C17.1077 5.79396 16.7911 5.79396 16.5958 5.98922L11.9996 10.5854L7.40343 5.98922C7.20817 5.79396 6.89159 5.79396 6.69633 5.98922L5.98922 6.69633Z" />
    </svg>
  );
};

export default Icon;
