import { FC } from "react";

const data = [
  { rel: "shortcut icon", href: "/favicon.ico" },
  { rel: "icon", sizes: "16x16 32x32 64x64", href: "/favicon.ico" },
  {
    rel: "icon",
    type: "image/png",
    sizes: "196x196",
    href: "/favicon-192.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "160x160",
    href: "/favicon-160.png",
  },
  { rel: "icon", type: "image/png", sizes: "96x96", href: "/favicon-96.png" },
  { rel: "icon", type: "image/png", sizes: "64x64", href: "/favicon-64.png" },
  { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32.png" },
  { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16.png" },
  { rel: "apple-touch-icon", href: "/favicon-57.png" },
  { rel: "apple-touch-icon", sizes: "114x114", href: "/favicon-114.png" },
  { rel: "apple-touch-icon", sizes: "72x72", href: "/favicon-72.png" },
  { rel: "apple-touch-icon", sizes: "144x144", href: "/favicon-144.png" },
  { rel: "apple-touch-icon", sizes: "60x60", href: "/favicon-60.png" },
  { rel: "apple-touch-icon", sizes: "120x120", href: "/favicon-120.png" },
  { rel: "apple-touch-icon", sizes: "76x76", href: "/favicon-76.png" },
  { rel: "apple-touch-icon", sizes: "152x152", href: "/favicon-152.png" },
  { rel: "apple-touch-icon", sizes: "180x180", href: "/favicon-180.png" },
];

interface FaviconsProps {
  favicon: string;
}

type FaviconsType = {
  rel: string;
  type?: string;
  sizes?: string;
  href: string;
};

const Favicons: FC<FaviconsProps> = ({ favicon }) => {
  const renderIcon = (item: FaviconsType, index: number) => (
    <link
      key={`favicon-item-${index}`}
      rel={item.rel}
      type={item.type}
      sizes={item.sizes}
      href={favicon ?? item.href}
    />
  );
  return <>{data.map(renderIcon)}</>;
};

export default Favicons;
