import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import { ClientSchema } from "@lib/data/schemas/client";

const tags = ["clients"];

const ClientCommonPathSchema = z.object({
  apiVersion: z.string(),
  userId: z.string(),
  clientId: z.string(),
});

/* /api/{apiVersion}/users/{userId}/clients/{clientId}  */

const UpdateClientSchema = ClientSchema.partial().extend({
  assigneeId: z.string().optional(),
  assignedBy: z.string().optional(),
});

export type ClientUpdate = z.infer<typeof UpdateClientSchema>;

const UpdateClientResponseSchema = z.object({
  status: z.string(),
});

export const putUpdateClient = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/clients/{clientId}",
  "put",
  {
    path: ClientCommonPathSchema,
    body: UpdateClientSchema,
  },
  {
    description: "Updates client",
    tags,
  },
  UpdateClientResponseSchema
);

export const deleteDeleteClient = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/clients/{clientId}",
  "delete",
  {
    path: z.object({
      apiVersion: z.string(),
      userId: z.string(),
      clientId: z.string(),
    }),
  },
  {
    description: "Deletes client",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

export const putAssignClient = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/assignee",
  "put",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
    }),
    body: z.object({
      accountId: z.string(),
      assignedBy: z.string().optional(),
    }),
  },
  {
    description: "Assign a team member to a client",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

export const getAssignClient = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/assignee",
  "get",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
    }),
  },
  {
    description: "Get the owner of a client",
    tags,
  },
  z.object({
    accountId: z.string(),
  })
);

const ClientMemberSchema = {
  path: z.object({
    userId: z.string(),
    clientId: z.string(),
  }),
  body: z.object({
    childClientId: z.string(),
  }),
};

export const addClientMember = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/member",
  "post",
  ClientMemberSchema,
  {
    description: "Adding a member to a client",
    tags,
  },
  z.object({})
);

export const removeClientMember = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/member",
  "delete",
  ClientMemberSchema,
  {
    description: "Removing a member from a client",
    tags,
  },
  z.object({})
);
