import { z } from "zod";

import { getSchemaDefinition } from "../common";

const LearnMoreItemCategory = z.enum([
  "appointments",
  "availability",
  "forms",
  "services",
  "library",
  "contacts",
  "inbox",
  "account-management",
  "profile",
]);

export type LearnMoreItemCategoryType = z.infer<typeof LearnMoreItemCategory>;

const LearnMoreItemsQuerySchema = z.object({
  category: LearnMoreItemCategory,
});

const LearnMoreItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  headline: z.string(),
  article: z.string(),
  icon: z.string(),
  categoryId: z.string(),
  order: z.number(),
});

export type LearnMoreItemType = z.infer<typeof LearnMoreItemSchema>;

export const getWebflowLearnMoreItems = getSchemaDefinition(
  "/api/v1/webflow/learn-more",
  "get",
  {
    query: LearnMoreItemsQuerySchema,
  },
  {
    description: "Get learn more items",
    tags: ["webflow"],
  },
  LearnMoreItemSchema.array()
);

const VideoItemSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  description: z.string().optional(),
  thumbnailUrl: z.string().optional(),
  youtubeUrl: z.string(),
  categoryId: z.string().optional(),
  order: z.number(),
  length: z.number().optional(),
});

export type VideoItemType = z.infer<typeof VideoItemSchema>;

const VideoItemCategory = z.enum(["get-started"]);

export type VideoItemCategoryType = z.infer<typeof VideoItemCategory>;

const VideoItemsQuerySchema = z.object({
  category: VideoItemCategory,
});

export const getWebflowVideos = getSchemaDefinition(
  "/api/v1/webflow/videos",
  "get",
  {
    query: VideoItemsQuerySchema,
  },
  {
    description: "Get videos",
    tags: ["webflow"],
  },
  VideoItemSchema.array()
);

const WebflowVideoPathSchema = z.object({
  videoId: z.string(),
});

export const getWebflowVideo = getSchemaDefinition(
  "/api/v1/webflow/videos/{videoId}",
  "get",
  {
    path: WebflowVideoPathSchema,
  },
  {
    description: "Get single video by id",
    tags: ["webflow"],
  },
  VideoItemSchema
);
