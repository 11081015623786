import { z } from "zod";

import {
  SubscriptionCouponSchema,
  SubscriptionPlanSchema,
} from "@lib/data/schemas/subscription-plan";

import { getSchemaDefinition } from "../common";

const tags = ["stripe"];

const StripeCommonPathSchema = z.object({
  userId: z.string(),
  subscriptionId: z.string(),
});

const StripeSubscriptionPlanFrequencyEnum = z.enum(["month", "year"]);

const StripeSubscriptionPlanInvoicesGetResponseSchema = z
  .object({
    id: z.string(),
    paidAt: z.date(),
    amount: z.number(),
    invoicePdf: z.string(),
    currency: z.string(),
    createdAt: z.date(),
    subscription: z.object({
      title: z.string(),
      frequency: StripeSubscriptionPlanFrequencyEnum,
    }),
    promoCodeName: z.string().optional(),
  })
  .array();

export const getStripeSubscriptionPlanInvoices = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions/{subscriptionId}/invoices",
  "get",
  {
    path: StripeCommonPathSchema,
  },
  {
    description: "Returns all past invoices related to the coach subscription",
    tags,
  },
  StripeSubscriptionPlanInvoicesGetResponseSchema
);

const AmountSchema = z.object({
  amount: z.number(),
  description: z.string(),
  isTrial: z.boolean().optional(),
});

const ExtendedStripeSubscriptionPlanSchema = SubscriptionPlanSchema.extend({
  amount: z.number(),
  currency: z.string(),
  defaultCoupon: SubscriptionCouponSchema.optional(),
  metadata: z.any().optional(),
});

const StripeSubscriptionPlansGetResponseSchema =
  ExtendedStripeSubscriptionPlanSchema.array();

const StripeSubscriptionTrialEndSchema = z.union([z.number(), z.enum(["now"])]);

export type StripeSubscriptionTrialEndType = z.infer<
  typeof StripeSubscriptionTrialEndSchema
>;

export type ExtendedStripeSubscriptionPlan = z.infer<
  typeof ExtendedStripeSubscriptionPlanSchema
>;

export const getStripeSubscriptionPlans = getSchemaDefinition(
  "/api/v1/subscription-plans",
  "get",
  {},
  {
    description: "Return all subscription plans related to Practice",
    tags,
  },
  StripeSubscriptionPlansGetResponseSchema
);

export const getStripeMemberSubscriptionPlans = getSchemaDefinition(
  "/api/v1/members-subscription-plans",
  "get",
  {
    query: z.object({
      yearly: z.string().optional(),
    }),
  },
  {
    description: "Return all member subscription plans related to Practice",
    tags,
  },
  StripeSubscriptionPlansGetResponseSchema
);

export const getStripeSubscriptionUpdatePreview = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions/{subscriptionId}/update-preview",
  "get",
  {
    path: StripeCommonPathSchema,
    query: z.object({
      previewPriceId: z.string().optional(),
      customPrice: z.string().optional(),
    }),
  },
  {
    description: "Return subscription update preview information",
    tags,
  },
  z.object({
    currency: z.string(),
    total: z.number(),
    credits: z.number(),
    preview: AmountSchema,
    creditsUsed: AmountSchema.optional(),
    current: AmountSchema.optional(),
  })
);

export const updateStripeSubscription = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions/{subscriptionId}/update",
  "post",
  {
    path: StripeCommonPathSchema,
    query: z.object({
      newPriceId: z.string(),
    }),
    body: z.object({
      promoCodeId: z.string().optional(),
      paymentMethodId: z.string().optional(),
      trialEnd: StripeSubscriptionTrialEndSchema.optional(),
      prorationBehavior: z
        .enum(["create_prorations", "always_invoice", "none"])
        .optional(),
    }),
  },
  {
    description: "Update stripe subscription",
    tags,
  },
  z.object({})
);

export const changeSubscriptionPaymentMethod = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions/{subscriptionId}/change-payment-method",
  "post",
  {
    path: StripeCommonPathSchema,
    body: z.object({
      paymentMethodId: z.string(),
      promoCodeId: z.string().optional(),
      trialEnd: StripeSubscriptionTrialEndSchema.optional(),
    }),
  },
  {
    description: "Change stripe subscription payment method",
    tags,
  },
  z.object({})
);

export const getStripeSubscription = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions",
  "get",
  {
    path: z.object({ userId: z.string() }),
  },
  {
    description: "Get the coach subscription",
    tags,
  },
  z.object({
    subscription: z.any(),
    membersSubscription: z.any().optional(),
    customer: z.any(),
    status: z.string(), // Legacy field used by mobile apps
  })
);

export const getStripeSubscriptionUpcomingInvoice = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions/{subscriptionId}/upcoming",
  "get",
  {
    path: StripeCommonPathSchema,
  },
  {
    description: "Get the coach upcoming invoice subscription",
    tags,
  },
  z.object({
    upcomingInvoice: z.any(),
    membersUpcomingInvoice: z.any().optional(),
  })
);

const CreateStripeSubscriptionBodySchema = z.object({
  paymentMethodId: z.string().nullable().optional(),
  promoCodeId: z.string().nullable().optional(),
  referredBy: z.string().nullable().optional(),
  priceId: z.string().nullable().optional(),
  // when prop `cancelCurrentSubscriptionId` is set, it should cancel
  // the current subscription first in order to add a new one
  cancelCurrentSubscriptionId: z.string().nullable().optional(),
  trialEnd: StripeSubscriptionTrialEndSchema.optional(),
});

export type CreateStripeSubscriptionBodyType = z.infer<
  typeof CreateStripeSubscriptionBodySchema
>;

export const createStripeSubscription = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/subscriptions",
  "post",
  {
    path: z.object({ userId: z.string() }),
    body: CreateStripeSubscriptionBodySchema,
  },
  {
    description: "Create stripe subscription",
    tags,
  },
  z.object({ subscription: z.object({ id: z.string() }) })
);

export const postStripeSubscriptionInvoicePay = getSchemaDefinition(
  "/api/v1/users/{userId}/stripe/invoices/{invoiceId}/pay",
  "post",
  {
    path: z.object({ userId: z.string(), invoiceId: z.string() }),
    body: z.object({ promoCodeId: z.string().optional() }),
  },
  {
    description: "Try to process the invoice related to the subscription",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

const StripeSubscriptionValidatePromoCodePostResponseSchema = z.object({
  valid: z.boolean(),
  type: z.enum(["promo", "referral"]),
  promoCodeId: z.string().optional(),
  couponId: z.string().optional(),
  referredBy: z.string().optional(),
  coupon: SubscriptionCouponSchema.optional(),
});

export type StripeSubscriptionValidatePromoCodePostResponseType = z.infer<
  typeof StripeSubscriptionValidatePromoCodePostResponseSchema
>;

export const postStripeSubscriptionValidatePromoCode = getSchemaDefinition(
  "/api/v1/promo-codes/{promoCode}/validate",
  "post",
  {
    path: z.object({ promoCode: z.string() }),
  },
  {
    description:
      "Validate promo code to be used with Practice subscription plans",
    tags,
  },
  StripeSubscriptionValidatePromoCodePostResponseSchema
);
