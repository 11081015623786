import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { SmartActionTemplateSchema } from "./smart-action-template";

export const InvoiceStatusEnum = z.enum([
  "open",
  "draft",
  "shared",
  "scheduled",
  "viewed",
  "paid",
  "void",
  "failed",
  "deleted",
  "processing",
]);
export type InvoiceStatusEnumType = z.infer<typeof InvoiceStatusEnum>;

export const InvoiceCustomFieldSchema = z.object({
  title: z.string(),
  value: z.string(),
});
export type InvoiceCustomFieldType = z.infer<typeof InvoiceCustomFieldSchema>;

export const InvoiceItemSchema = z.object({
  productId: z.string().optional(),
  description: z.string(),
  //@TODO: Fix this type
  quantity: z.string().or(z.number()),
  unitAmount: z.number().min(0), // In cents
  itemAmount: z.number().min(0), // In cents
  taxTypeId: z.string(),
  taxAmount: z.number().min(0), // In cents
  taxInclusive: z.boolean(),
  transactionId: z.string().optional(),
});
export type InvoiceItemType = z.infer<typeof InvoiceItemSchema>;

export const FrequencySchema = z.object({
  interval: z.string(),
  intervalCount: z.number().optional(),
  paymentCount: z.number().optional(),
});

const InvoiceKind = z.enum(["usage-based"]);
export type InvoiceKindType = z.infer<typeof InvoiceKind>;

export const InvoiceSchema = BaseSchema.extend({
  payeeId: z.string(),
  contactId: z.string(),
  couponId: z.string().optional(),
  status: InvoiceStatusEnum,
  dueDate: z.date().optional(),
  currency: z.string(),
  amount: z.number(), // In cents
  tax: z.number().min(0).optional(), // In cents
  discount: z.number().min(0).optional(), // In cents
  total: z.number(), // In cents
  recurring: FrequencySchema.optional(),
  subscriptionId: z.string().optional(),
  memo: z.string().optional(),
  footer: z.string().optional(),
  customFields: z.array(InvoiceCustomFieldSchema).optional(),
  items: z.array(InvoiceItemSchema).optional(),
  stripe: z.object({
    customer: z.any(),
  }),
  invoice: z.any(),
  subscription: z.string().optional(),
  scheduledSmartActions: z.array(z.string()).optional(),
  sendAt: z.date().optional(),
  smartActionTemplates: z.array(SmartActionTemplateSchema).optional(),
  isUpcomingInvoice: z.boolean().optional(),
  wasScheduled: z.boolean().optional(),
  paymentMethod: z.string().optional(),
  paidAt: z.date().optional(),
  kind: InvoiceKind.optional(),
});
export type InvoiceType = z.infer<typeof InvoiceSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "invoices",
  deleteMode: "soft",
};
