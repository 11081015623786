import { useMemo } from "react";
import { SDKUtils } from "@practice/sdk";

type HookType = (origin?: string) => (localOrigin?: string) => string;

export const useRequestIdGenerator: HookType = (origin?: string) => {
  const generator = useMemo(() => {
    const generatorFunction = (localOrigin?: string) => {
      if (!origin && !localOrigin) {
        throw new Error("Origin is required");
      }

      return SDKUtils.generateRequestId({
        instance: "WEB",
        origin: localOrigin ?? origin ?? "unknown",
      });
    };

    return generatorFunction;
  }, [origin]);

  return generator;
};
